import { DPCompany, DPContact, DPProject } from "@ez/design-system-data-linker";
import { DataRuleSet } from "../../utils/helpers";
export { Selector } from "./Selector";

export type MentionUser = {
  id: string;
  name: string;
};

export interface Company
  extends Omit<
    DPCompany,
    "id" | "name" | "logo" | "projectPosition" | "clientCompany"
  > {
  id: number | null;
  name?: string;
  logo?: string;
  type?: string;
  showId?: string;
  projectPosition?: string;
  clientCompany?: string;
}

export interface Contact
  extends Omit<
    DPContact,
    | "id"
    | "firstName"
    | "lastName"
    | "position"
    | "image"
    | "company"
    | "email"
    | "isBlocked"
  > {
  id: number | null;
  firstName?: string;
  lastName?: string;
  position?: string;
  image?: string;
  company?: string;
  isBlocked?: boolean;
  name?: string;
  email?: string;
  type?: string;
  showId?: string;
}

export interface Project
  extends Omit<DPProject, "id" | "name" | "clientCompany" | "projectPosition"> {
  id: number | null;
  name?: string;
  logo?: string;
  type?: string;
  showId?: string;
  clientCompany?: string;
  projectPosition?: string;
}

export type NoteType = {
  id: string | null;
  icon?: string;
  label?: string;
  dataRuleSet?: DataRuleSet;
};

export type DefaultNoteData = {
  client: Contact;
  candidate: Contact;
  company: Company;
  project: Project;
  onBehalfOf: Contact;
};

export type Note = {
  client: Contact;
  candidate: Contact;
  company: Company;
  project: Project;
  type: NoteType;
  comment: string;
  file?: File;
  onBehalfOf: Contact;
  primaryPerson: Contact;
  secondaryPerson: Contact;
};

export type PossibleMentionType = Company | Contact | Project;

export type MentionOptions = {
  openingCharacter: string;
  resultData: Array<PossibleMentionType>;
  setSearchTerm: (searchTerm: string) => void;
  mentionList: { [key: string]: PossibleMentionType };
  setMentionList: (mentionList: { [key: string]: PossibleMentionType }) => void;

  customComponent: React.FC<{
    item: PossibleMentionType;
    key: string;
    onClick: (item: PossibleMentionType) => void;
  }>;
};

export enum TargetType {
  Company = "Company",
  Contact = "Contact",
  Project = "Project",
}

export type MentionItem = {
  targetId: number | null;
  displayName: string | undefined;
  targetType: TargetType;
};
