import React from "react";
import styled from "@emotion/styled";

import { LogoZendai } from "@ez/design-system";
import { Icon } from "./Icon";

type Props = {
  onCancel: () => void;
  handleSignOut: () => void;
};

export const SelectorMenu: React.FC<Props> = ({ onCancel, handleSignOut }) => {
  const version = "Version 1.6";
  return (
    <ConfigurationContainer>
      <StyledContainer>
        <StyledLogoContainer>
          <LogoZendai type={"LOGO_ONLY"} />
        </StyledLogoContainer>

        <StyledCloseButton onClick={onCancel}>
          <p>Close</p>
          <img src={"/icons/close.svg"} alt={"close"} height={20} width={20} />
        </StyledCloseButton>
      </StyledContainer>
      <StyledVersion>{version}</StyledVersion>

      <StyledLogoutContainer onClick={handleSignOut}>
        <Icon type={"logout"} fill="black" />
        <p>Log out</p>
      </StyledLogoutContainer>
      <StyledHR />
    </ConfigurationContainer>
  );
};

const StyledHR = styled("hr")`
  align-self: center;
  width: 80%;
  border: 0;
  border-top: 2px solid #e0e0e0;
  margin: 0;
  padding: 0;
`;

const StyledLogoutContainer = styled("div")`
  display: flex;
  flex-direction: row;
  width: 80%;
  padding-bottom: 15px;
  align-items: center;
  align-self: center;
  font-weight: 600;
  gap: 10px;
  color: #000000;
  cursor: pointer;
`;

const StyledVersion = styled("p")`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 500;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  width: 80%;
  align-self: center;
  font-family: inherit;
`;

const ConfigurationContainer = styled.div`
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;

  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const StyledLogoContainer = styled("div")`
  height: 25px;
  width: 25px;
  margin-left: 12.5px;
  margin-bottom: 10px;
  top: 0;
`;

const StyledCloseButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: #375f86;
  font: inherit;

  background-color: white;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-right: 10px;
`;
const StyledContainer = styled("div")`
  display: flex;
  gap: 10px;
  border: 0;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  height: 48px;
`;
