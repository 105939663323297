export const fontFamily = "font-family: 'Messina Sans', Arial, sans-serif;";

// NOTE: the old `font-size` mixin is now deprecated. please use `calculateRem` directly when
// providing `font-size` to a style definition.

// calculateRem accepts a size as a string (e.g. 48px) and returns the output in rem units.
// because calc is used in its implementation, there is no need to specify rem in the output.
export const calculateRem = (size: string) => {
  return `calc(${size} / 16px) * 1rem`;
};

export const bodyS = `
    ${fontFamily}
    font-size: ${calculateRem("12px")}
    line-height: 20px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0px;
`;

export const bodySCompact = `
    ${bodyS}
    line-height: 14px;
`;

export const bodyM = `
    ${fontFamily}
    font-size: ${calculateRem("14px")}
    line-height: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0px;
`;

export const bodyMCompact = `
    ${bodyM}
    line-height: 18px;
`;

export const bodyL = `
    ${fontFamily}
    ${calculateRem("16px")}
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0px;
`;

export const bodyLCompact = `
    ${bodyL}
    line-height: 20px;
`;

export const bodyLBoldCompact = `
    ${bodyLCompact}
    font-weight: bold; 
`;

export const headingS = `
  ${fontFamily}
  ${calculateRem("16px")}
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
`;

export const headingM = `
  ${fontFamily}
  ${calculateRem("20px")}
  line-height: 28px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
`;

export const headingL = `
  ${fontFamily}
  ${calculateRem("24px")}
  line-height: 32px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
`;
