import React from "react";
import styled from "@emotion/styled";
import { IBaseDataModel } from "./SearchBarListHeader";
import LoadingWrapper from "./utils/LoadingWrapper";
import LoadingView from "./LoadingView";
import GeneralErrorView from "./GeneralErrorView";
import { LoadingState } from "../utils";

type Props<T extends IBaseDataModel> = {
  /**
   * Provides the list of items to be displayed in the list.
   */
  listData: T[];
  /**
   * Called when the user cancels out of the search bar list.
   * @returns void
   */
  onCancel: () => void;
  /**
   * Called when the user selects an item from the list.
   * @param item The item the user selected.
   * @returns void
   */
  onSelection: (item: T) => void;
  /**
   * Component used to render an item in the list view.
   * @param item The item to be rendered.
   * @returns ReactNode
   */
  renderItem: (item: T) => React.ReactNode;
  /**
   * Text string to display as the activity title in the button group header.
   */
  titleActivity: string;
  isLoading: boolean;
};

/**
 * Displays a listbox view the contains a header (mobile and tablet only) and list
 * of provided items. The renderItem handler is used to render each item in the list.
 * @param listData Provides the list of items to be displayed in the list.
 * @param onCancel Called when the user cancels out of the search bar list.
 * @param onSelection Called when the user selects an item from the list.
 * @param renderItem Component used to render an item in the list view.
 * @param titleActivity Text string to display as the activity title in the button group header.
 * @returns ReactNode
 */
export const Listbox = <T extends IBaseDataModel>({ listData, onCancel, renderItem, titleActivity, isLoading }: Props<T>) => {
  return (
    <Container>
      <div style={{ position: "relative" }}>
        <TopTitleContainer>{titleActivity}</TopTitleContainer>
        <StyledCloseButton onClick={onCancel} src="/icons/close.svg" alt={"close-icon"} height={20} width={20} draggable={false} />
      </div>
      {isLoading ? (
        <LoadingWrapper
          loading={isLoading ? LoadingState.Loading : LoadingState.Ready}
          onLoading={<LoadingView data-cy="srch-bar-loading" />}
          onError={<GeneralErrorView text={"Error loading. Please reach out to support or try again."} />}
        />
      ) : (
        <Content data-cy="listbox-content">
          {listData &&
            listData?.length > 0 &&
            listData.map((result) => (
              <div data-cy="listbox-item" key={result.id}>
                {renderItem(result)}
              </div>
            ))}
        </Content>
      )}
    </Container>
  );
};

export default Listbox;

const TopTitleContainer = styled.p`
  height: 42px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 0px;
  position: relative;
  padding-left: 20px;
`;

const StyledCloseButton = styled.img`
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  background: white;
  flex-grow: 1;
  padding: 0px 16px 0;
`;
