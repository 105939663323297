import * as React from "react";
import { Project } from ".";
import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";

type ProjectDropdownItemProps = {
  onClick: (item: Project) => void;
  key: string | number;
  item: Project;
  cssOverride?: string;
};

export const ProjectDropdownItem = ({
  item,
  key,
  onClick,
  cssOverride,
}: ProjectDropdownItemProps) => {
  const { name, clientCompany, projectPosition } = item;

  return (
    <StyleCustomComponent
      disableRipple
      key={key}
      onClick={() => onClick(item)}
      cssOverride={cssOverride ?? ""}
    >
      <StyledDiv>
        <BoldSpan>{name}</BoldSpan>
        <StyledSpan>{clientCompany}</StyledSpan>
        <StyledSpan>{projectPosition}</StyledSpan>
      </StyledDiv>
    </StyleCustomComponent>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const StyledSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyleCustomComponent = styled(ListItemButton)<{ cssOverride: string }>`
  span {
    font-size: 0.875rem;
  }
  color: #57595b;
  font-family: "Messina Sans";
  font-weight: 400;
  line-height: 1.25rem;
  border-bottom: 0.5px solid #c6c7c8;

  /* border-left-width: 4px;
  border-style: solid;
  border-color: #ffffff; */

  :first-child {
    border-top: 1px solid #dbd5cd;
  }

  min-height: 44px;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;

  &:hover {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 
    background-color: #ffffff;
    */
    background-color: #efefef;

    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;

    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:focus {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 
    background-color: #ffffff;
    */
    background-color: #efefef;

    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;

    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:active {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 
    background-color: #375f86;*/
    background-color: #efefef;

    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  ${(props) => props.cssOverride}
`;
