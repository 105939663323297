import React from "react";
import styled from "@emotion/styled";
import { LabelCell, TUnionCell, ValueListCell } from "../components/ListCell";
import SearchInput, { SearchInputProps } from "../components/SearchInput";
import MissingIndicator from "./MissingIndicator";

export type TCellGroup = {
  title?: string;
  type: "basic" | "value";
  search?: SearchInputProps;
  cells: TUnionCell[];
  isOptionalCellGroup?: boolean;
  isTypeField?: boolean;
};

type GroupList = {
  groups: TCellGroup[];
};

/**
 * @method CellGroup
 * @description CellGroup displays a list of Cells with an optional title.
 * @param {string} [title] - The title of the cell group
 * @param {string} type - The type of the CellGroup. Can be "basic" or "selectable"
 * @param {SearchInputProps} [search] - If populated, determines whether a search field is available.
 * @param {TSelectableCell[]} cells - A list of cells that are rendered within the group. `TSelectableCell` covers both basic and selectable variants of Cells.
 * @returns {ReactNode}
 */
export const CellGroup: React.FC<TCellGroup> = ({
  title,
  type,
  search,
  cells,
  isOptionalCellGroup = false,
  isTypeField,
}) => {
  return (
    <div className={"cell-group-container"}>
      {title && <LabelCell title={title} />}
      {search && (
        <SearchContainer className={"cell-group-search-container"}>
          <SearchInput
            placeholder={search.placeholder}
            testId={search.testId}
            debounceTime={search.debounceTime}
            onChange={search.onChange}
            onCancel={search.onCancel}
            showCancel={search.showCancel}
            disabled={search.disabled}
          />
        </SearchContainer>
      )}
      {cells.length > 0 &&
        cells.map((c, idx) => {
          return (
            <>
              {!c.isHidden && (
                <>
                  {c.isRequired && (
                    <MissingIndicator customLeft={isOptionalCellGroup ? "16px" : "0px"} />
                  )}
                  <ValueListCell
                    key={title + c.headline + idx}
                    hasTitle={!!title}
                    headline={c.headline}
                    value={c.value}
                    onClick={c.onClick}
                    testId={c.testId}
                    icon={c.icon}
                    iconCircleColor={c.iconCircleColor}
                    collapsed={c.collapsed}
                    isOptionalCellGroup={isOptionalCellGroup}
                    isTypeField={isTypeField}
                  />
                </>
              )}
            </>
          );
        })}
    </div>
  );
};

/**
 * @method CellGroupList
 * @description CellGroupList displays multiple groups of Cells.
 * @param {TCellGroup[]} groups - list of CellGroups to be rendered.
 * @returns {ReactNode}
 */
export const CellGroupList: React.FC<GroupList> = ({ groups }) => {
  return (
    <>
      {groups.map((group, idx) => (
        <div key={group.type + group.title + idx}>
          {idx > 0 && <GroupSeparator />}
          <CellGroup type={group.type} title={group.title} cells={group?.cells} />
        </div>
      ))}
    </>
  );
};

const GroupSeparator = styled.div`
  height: 8px;
`;

export const CellGroupContainer = styled.div`
  background: white;
`;

const SearchContainer = styled.div`
  padding: 0 8px;

  > .search-bar-container {
    padding: 0 0 12px;
  }
`;
