import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const LoadingSpinner = () => {
  return (
    <StyledImage
      src={"/icons/spinner.svg"}
      alt={"Loading"}
      width={32}
      height={32}
    />
  );
};

export default LoadingSpinner;

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
    `;

const StyledImage = styled("img")`
  animation: ${rotationAnimation} 0.6s infinite;
`;
