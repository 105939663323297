import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { PopupRequest } from "@azure/msal-browser/dist/request/PopupRequest";
import { Note } from "../components/Selector";

export const fetchAuthToken = async (
  instance: IPublicClientApplication,
  loginRequest: PopupRequest
) => {
  try {
    const response = await instance.acquireTokenSilent(loginRequest);
    return response.accessToken;
  } catch (error) {
    try {
      await instance.acquireTokenRedirect(loginRequest);
      const result = await instance.acquireTokenSilent(loginRequest);
      return result.accessToken;
    } catch (error) {
      console.log(error);
    }
    return "";
  }
};

export type DataRuleSet = {
  type: string;
  Client: string;
  Candidate: string;
  Project: string;
  Company: string;
  Comments: string;
  OnBehalfOf?: string;
} | null;

export const getDataByType = (type: string): DataRuleSet => {
  const data = [
    {
      type: "Pitch deck",
      Client: "Optional",
      Candidate: "Hidden",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Search strategy",
      Client: "Optional",
      Candidate: "Hidden",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Letter of proposal",
      Client: "Optional",
      Candidate: "Hidden",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Role specification",
      Client: "Optional",
      Candidate: "Hidden",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Confidential report",
      Client: "Optional",
      Candidate: "Required",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Board dossier",
      Client: "Optional",
      Candidate: "Required",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Reference report",
      Client: "Optional",
      Candidate: "Required",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Final offer letter",
      Client: "Optional",
      Candidate: "Required",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Mapping",
      Client: "Optional",
      Candidate: "Optional",
      Project: "Required",
      Company: "Future release",
      Comments: "Optional",
      OnBehalfOf: "Optional",
    },
    {
      type: "Other project documents",
      Client: "Optional",
      Candidate: "Optional",
      Project: "Required",
      Company: "Future release",
      Comments: "Required",
      OnBehalfOf: "Optional",
    },
    {
      type: "Client-provided documents",
      Client: "Required",
      Candidate: "Optional",
      Project: "Required",
      Company: "Future release",
      Comments: "Required",
      OnBehalfOf: "Optional",
    },
  ];

  const result = data.find((item) => item.type === type);
  return result ? result : null;
};

export const getPrimaryAndSecondaryPerson = (note: Note): Note => {
  note.primaryPerson = note.client ? note.client : { id: null };
  note.secondaryPerson = note.candidate ? note.candidate : { id: null };

  return { ...note };
};
