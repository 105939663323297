import React from "react";
import styled from "@emotion/styled";

export const FEEDBACK_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  LOADING: "LOADING",
} as const;

export type Feedback = (typeof FEEDBACK_TYPE)[keyof typeof FEEDBACK_TYPE];

type Props = {
  type?: Feedback;
  message: string;
};

type IconStyle = {
  src: string;
  alt: string;
  size: number;
};

export const FeedbackDisplay: React.FC<Props> = ({
  type = FEEDBACK_TYPE.SUCCESS,
  message,
}) => {
  let iconStyles: IconStyle;

  // TODO: reverse
  switch (type) {
    case FEEDBACK_TYPE.SUCCESS:
      iconStyles = {
        src: "/icons/checkmark.svg",
        alt: "success",
        size: 18,
      };
      break;

    case FEEDBACK_TYPE.ERROR:
      iconStyles = {
        src: "/icons/exmark.svg",
        alt: "error",
        size: 12,
      };
      break;

    case FEEDBACK_TYPE.LOADING:
      iconStyles = {
        src: "/icons/floppy-disk.svg",
        alt: "loading...",
        size: 14,
      };
      break;
  }

  return (
    <Container type={type}>
      <FeedbackContainer>
        <img
          src={iconStyles.src}
          alt={iconStyles.alt}
          width={iconStyles.size}
          height={iconStyles.size}
          draggable={false}
        />
        {message}
      </FeedbackContainer>
    </Container>
  );
};

const getColor = (type: Feedback) => {
  switch (type) {
    case FEEDBACK_TYPE.SUCCESS:
      return "#5cb85c";
    case FEEDBACK_TYPE.ERROR:
      return "#94152D";
    case FEEDBACK_TYPE.LOADING:
      return "#375F86";
  }
};

const Container = styled("p")<{ type: Feedback }>`
  color: ${({ type }) => getColor(type)};
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  margin-left: 16px;
`;

const FeedbackContainer = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: -8px;
`;
