import React from "react";

type Props = {
  customLeft?: string;
};

const MissingIndicator: React.FC<Props> = ({ customLeft = "0px" }) => {
  return (
    <div
      style={{
        height: "46px",
        width: "4px",
        backgroundColor: "red",
        position: "absolute",
        left: customLeft,
        zIndex: 1,
      }}
    />
  );
};

export default MissingIndicator;
