import React, { ChangeEvent, ReactNode, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { debounce } from "lodash";

const CANCEL_LINK = "Cancel";

const grey = "#D0C5B4";

export type SearchInputProps = {
  /**
   * Event handler that is passed down to the underlying input element
   */
  onChange?: ((query: string) => void) | undefined;
  /**
   * placeholder is a string that determines the placeholder value of the input field
   */
  placeholder?: string;
  /**
   * debounceTime is a number value that determines the length of the time before firing `onChange`
   */
  debounceTime?: number;
  /**
   * testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button
   */
  testId?: string;
  /**
   * if true the cancel link should be shown when an the user is performing a search
   */
  showCancel?: boolean;
  /**
   * Children nodes intended to provide additional icon button content positioned
   * to the right of the search input.
   */
  children?: ReactNode;
  onCancel?: () => void;
  disabled?: boolean;
};

/**
 * @method SearchInput
 * @description Search input field
 * @param {ChangeEventHandler<HTMLInputElement>} [onChange] - Event handler that is passed down to the underlying input element.
 * @param {string} [placeholder] - placeholder is a string that determines the placeholder value of the input field
 * @param {number} [debounceTime] - debounceTime is a number value that determines the length of the time before firing `onChange`
 * @param {string} [testId] - testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button
 * @param {boolean} [showCancel] - if true the cancel link should be shown when an the user is performing a search
 * @param {() => void} [onCancel] - optional event handler that is connected to the cancel button
 * @param {ReactNode} [children] - Children nodes intended to provide additional icon button content positioned to the right of the search input.
 * @returns {ReactNode}
 */
const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  placeholder,
  debounceTime,
  testId,
  showCancel = false,
  onCancel,
  children,
  disabled,
}) => {
  const [searchHasFocus, setSearchHasFocus] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Memoizes the debounced handler so debouncer works. Without memoization
  // if debounce was called and returning a new method each time, each
  // debounce method would only see a single call and no debouncing would
  // occur.
  const handleDebouncedOnChange = useMemo(
    () => debounce(onChange || (() => {}), debounceTime),
    [debounceTime, onChange]
  );

  const handleOnChange = useMemo(
    () => (e: ChangeEvent<HTMLInputElement>) => {
      // Update search term so Cancel can be shown/hidden appropriately.
      // This should be performed without any debounce filtering.
      setSearchTerm(e.target.value);

      if (!onChange) {
        return;
      }

      if (!debounceTime) {
        onChange(e.target.value);
        return;
      }

      handleDebouncedOnChange(e.target.value);
    },
    [onChange, debounceTime, handleDebouncedOnChange]
  );

  const handleOnCancel = useMemo(
    () => () => {
      setSearchTerm("");
      onCancel && onCancel();
    },
    [onCancel]
  );

  return (
    <Container className={"search-bar-container"}>
      <SearchIcon
        src={"/icons/search-icon.svg"}
        alt={"Search"}
        width={17}
        height={16}
        draggable={false}
      />
      <SearchField
        data-cy={`${testId ? `${testId}-searchfield` : "searchfield"}`}
        onBlur={() => setSearchHasFocus(false)}
        onChange={handleOnChange}
        onFocus={() => setSearchHasFocus(true)}
        placeholder={placeholder}
        value={searchTerm}
        disabled={disabled}
      />
      {showCancel && (searchHasFocus || searchTerm) && (
        <CancelContainer>
          <CancelLink onClick={handleOnCancel}>{CANCEL_LINK}</CancelLink>
        </CancelContainer>
      )}
      {children}
    </Container>
  );
};

export default SearchInput;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;

  > input[disabled] {
    background: ${grey};
  }
`;

const SearchField = styled.input`
  // TODO: update to use DS constants
  // Zendai/sys/light/surface
  background: #fdfcff;
  // TODO: Zendai/sys/light/outline-variant
  border: 1px solid #c1bbb4;
  border-radius: 10px;
  width: 100%;
  height: 32px;
  // bodyLCompact
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px; // TODO: Zendai/sys/light/on-surface
  color: #181818;
  padding-left: 38px;

  &::placeholder {
    // TODO: Zendai/state/light/state4
    color: rgba(0, 0, 0, 0.32);
  }
  &:focus {
    // Design calls for no outline when search has focus.
    // Need to override default user agent settings.
    outline: none;
  }
`;

const CancelLink = styled.button`
  word-break: keep-all;
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #375f86;
  margin-left: 16px;
  padding: 0;
`;

const SearchIcon = styled("img")`
  position: absolute;
  transform: translateX(12px);
`;

const CancelContainer = styled.span`
  display: flex;
  align-items: center;
`;
