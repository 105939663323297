import { assign, createMachine, MachineConfig } from "xstate";
import { FEEDBACK_TYPE, Feedback } from "./FeedbackDisplay";
import { getFileExtension } from "../../utils";
import { IS_NOT_VALID_FILE_EXTENSION } from "../utils/i18n";
import { Company, Contact, Note, NoteType, Project } from ".";
import { FILE_SIZE_LIMIT } from "../../services/constants";
import { getDataByType } from "../../utils/helpers";
import { UserInfo } from "./Selector";

const initialNote: Note = {
  company: { id: null },
  candidate: { id: null },
  client: { id: null },
  onBehalfOf: { id: null },
  project: { id: null },
  type: { id: null },
  comment: "",
  file: undefined,
  primaryPerson: { id: null },
  secondaryPerson: { id: null },
};

export const STATE = {
  IDLE: "IDLE",
  SELECTING: "SELECTING",
  UPLOADING: "UPLOADING",
};

export const EVENT = {
  BEGIN_SELECT: "BEGIN_SELECT",
  END_SELECT: "END_SELECT",
  SEND: "SEND",
  PROGRESS: "PROGRESS",
  READY: "READY",
  CHANGE_NOTE: "CHANGE_NOTE",
  CHANGE_TYPE: "CHANGE_TYPE",
  OPEN_MENU: "OPEN_MENU",
  CLOSE_MENU: "CLOSE_MENU",
};

const ACTION = {
  SET_IDLE: "SET_IDLE",
  SET_LOADING: "SET_LOADING",
  SHOW_SEARCH: "SHOW_SEARCH",
  HIDE_SEARCH: "HIDE_SEARCH",
  SET_NOTE: "SET_DATA",
  SET_PROGRESS: "SET_PROGRESS",
  SHOW_MENU: "SHOW_MENU",
  HIDE_MENU: "HIDE_MENU",
};

export const NOTE_EVENT_TYPE = {
  CONTACT: "CONTACT",
  COMPANY: "COMPANY",
  PROJECT: "PROJECT",
  TYPE: "TYPE",
  COMMENT: "COMMENT",
  FILE: "FILE",
  FILE_SIZE: "FILE_SIZE",
  MENU: "MENU",
  ON_BEHALF_OF: "ON_BEHALF_OF",
  CLIENT: "CLIENT",
  CANDIDATE: "CANDIDATE",
};

export type SelectorContext = {
  initialData?: InitialSelectorData;
  note: Note;
  editorType: any;
  isUploading: boolean;
  displayMessage: string;
  feedback?: Feedback;
  allowedFileExtensions: string[];
  conversationTypeCells: NoteType[];
  loggedInUser: UserInfo | null;
  fileSizeLimit: number;
};

export type InitialSelectorData = {
  allowedFileExtensions: string[];
  conversationTypeCells: NoteType[];
  loggedInUser: UserInfo;
  defaultClient?: Contact;
  defaultProject?: Project;
  defaultCompany?: Company;
  defaultCandidate?: Contact;
  fileSizeLimit: number;
};

export const getFileLimit = () => {
  if (process.env.REACT_APP_FILE_SIZE_LIMIT) {
    return +process.env.REACT_APP_FILE_SIZE_LIMIT;
  } else {
    return FILE_SIZE_LIMIT;
  }
};

export const initContext: SelectorContext = {
  note: initialNote,
  editorType: null,
  isUploading: false,
  displayMessage: "",
  feedback: undefined,
  allowedFileExtensions: [],
  conversationTypeCells: [],
  loggedInUser: null,
  fileSizeLimit: getFileLimit(),
};

const selectorMachine: MachineConfig<SelectorContext, any, any> = {
  id: "selector",
  initial: STATE.IDLE,
  states: {
    [STATE.IDLE]: {
      on: {
        [EVENT.BEGIN_SELECT]: STATE.SELECTING,
        [EVENT.SEND]: STATE.UPLOADING,
        [EVENT.CHANGE_NOTE]: {
          actions: [ACTION.SET_NOTE],
        },
        [EVENT.OPEN_MENU]: {
          actions: [ACTION.SHOW_MENU],
        },
        [EVENT.CLOSE_MENU]: {
          actions: [ACTION.HIDE_MENU],
        },
      },
      entry: [ACTION.SET_IDLE],
      exit: [ACTION.SET_LOADING],
    },
    [STATE.SELECTING]: {
      on: { [EVENT.END_SELECT]: STATE.IDLE },
      entry: [ACTION.SHOW_SEARCH],
      exit: [ACTION.HIDE_SEARCH, ACTION.SET_NOTE],
    },
    [STATE.UPLOADING]: {
      on: {
        [EVENT.PROGRESS]: STATE.UPLOADING,
        [EVENT.READY]: STATE.IDLE,
      },
      entry: [ACTION.SET_PROGRESS],
    },
  },

  /** XState: This flag is an opt into some fixed behaviors that will be the default in v5
   * https://xstate.js.org/docs/guides/actions.html#api
   */
  predictableActionArguments: true,
};

const selectorMachineSettings = {
  actions: {
    [ACTION.SET_IDLE]: assign({
      isUploading: false,
      displayMessage: (_, event: any) => event.displayMessage,
      feedback: (_, event) => event.feedback,
    }),

    [ACTION.SET_LOADING]: assign({
      isUploading: false,
      displayMessage: "",
    }),

    [ACTION.SHOW_SEARCH]: assign({
      editorType: (_, event: any) => event.data,
    }),

    [ACTION.SHOW_MENU]: assign({
      editorType: (_, event: any) => event.dataType,
    }),

    [ACTION.HIDE_MENU]: assign({
      editorType: () => null,
    }),

    [ACTION.HIDE_SEARCH]: assign({
      editorType: () => null,
    }),

    [ACTION.SET_NOTE]: assign((context: any, event: any) => {
      const oldNote = context.note;
      const result: {
        displayMessage: string;
        feedback: any;
        note: any;
      } = {
        displayMessage: "",
        feedback: null,
        note: oldNote,
      };
      const newData = event.data;

      switch (event.dataType) {
        case NOTE_EVENT_TYPE.CLIENT:
          result.note = {
            ...oldNote,
            client: newData,
          };
          break;

        case NOTE_EVENT_TYPE.CANDIDATE:
          result.note = {
            ...oldNote,
            candidate: newData,
          };
          break;

        case NOTE_EVENT_TYPE.COMPANY:
          result.note = {
            ...oldNote,
            company: newData,
          };
          break;

        case NOTE_EVENT_TYPE.PROJECT:
          result.note = {
            ...oldNote,
            project: newData,
          };
          break;

        case NOTE_EVENT_TYPE.ON_BEHALF_OF:
          result.note = {
            ...oldNote,
            onBehalfOf: newData,
          };
          break;

        case NOTE_EVENT_TYPE.TYPE:
          const newType = {
            ...newData,
            dataRuleSet: getDataByType(newData.label),
          };

          result.note = {
            ...oldNote,
            onBehalfOf: context.initialData.loggedInUser ? {
              id: context.initialData.loggedInUser.id,
              firstName: context.initialData.loggedInUser.name,
            } : { id: null },
            client: context.initialData.defaultClient || { id: null },
            candidate: context.initialData.defaultCandidate || { id: null },
            company: context.initialData.defaultCompany || { id: null },
            project: context.initialData.defaultProject || { id: null },
            type: newType,
          };
          break;

        case NOTE_EVENT_TYPE.COMMENT:
          result.note = {
            ...oldNote,
            comment: newData,
          };
          break;

        case NOTE_EVENT_TYPE.FILE_SIZE:
          result.displayMessage = `File size should be less than ${context.fileSizeLimit}MB`;
          result.feedback = FEEDBACK_TYPE.ERROR;

          result.note = oldNote;
          break;

        case NOTE_EVENT_TYPE.FILE:
          const fileExtension = getFileExtension(newData.name);

          if (context.allowedFileExtensions.includes(fileExtension)) {
            result.note = {
              ...oldNote,
              file: newData,
            };
          } else {
            result.displayMessage = fileExtension + IS_NOT_VALID_FILE_EXTENSION;
            result.feedback = FEEDBACK_TYPE.ERROR;

            result.note = oldNote;
          }

          break;

        default:
          console.warn("Event type not found");
      }

      return result;
    }),

    [ACTION.SET_PROGRESS]: assign({
      displayMessage: (_, event: any) => event.displayMessage,
      feedback: FEEDBACK_TYPE.LOADING,
    }),
  },
};

export const stateMachine = createMachine(
  selectorMachine,
  selectorMachineSettings
);
