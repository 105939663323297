import styled from "@emotion/styled";
import React from "react";
import { LogoCapture } from "./LogoCapture";

const ModalTitle: React.FC = () => {
  return (
    <StyledContainer>
      <LogoCapture />
    </StyledContainer>
  );
};

const StyledContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export default ModalTitle;
