import * as React from "react";
import { PossibleMentionType } from ".";
import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";

type CompanyDropdownItemProps = {
  onClick: (item: PossibleMentionType) => void;
  key: string;
  item: PossibleMentionType;
};

export const CompanyDropdownItem = ({ item, key, onClick }: CompanyDropdownItemProps) => {
  const { name } = item;

  return (
    <StyleCustomComponent disableRipple key={key} onClick={() => onClick(item)}>
      <StyledDiv>
        <StyledSpan>{name}</StyledSpan>
      </StyledDiv>
    </StyleCustomComponent>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyleCustomComponent = styled(ListItemButton)`
  span {
    font-size: 0.875rem;
  }
  color: #57595b;
  font-family: "Messina Sans";
  font-weight: 400;
  line-height: 1.25rem;

  border-left-width: 4px;
  border-style: solid;

  border-color: #ffffff;
  border-bottom: 1px #dbd5cd solid;

  :first-child {
    border-top: 1px solid #dbd5cd;
  }

  min-height: 44px;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;

  &:hover {
    color: #57595b;

    border-left-width: 4px;
    border-style: solid;
    border-color: #375f86;
    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;

    background-color: #ffffff;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:focus {
    color: #57595b;

    border-left-width: 4px;
    border-style: solid;
    border-color: #375f86;
    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;

    background-color: #ffffff;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:active {
    color: #57595b;

    border-left-width: 4px;
    border-style: solid;
    border-color: #375f86;

    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;
    background-color: #375f86;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;
