import { spacing } from "../spacing";

// Corner size
export const cornerS = `
    border-radius: ${spacing[2]};
    -webkit-border-radius: ${spacing[2]};
`;

export const cornerFull = `
    border-radius: 9999px;
    -webkit-border-radius: 9999px;
`;

// Border sizes
export const borderL = `
    border-width: ${spacing[1]};
`;

export const borderS = `
    border-width: 1px;
`;

export const outlineL = `
    outline-width: ${spacing[1]};
`;

export const outlineS = `
    outline-width: 1px;
`;

// Gradients
export const alphaGradient = `
    background: linear-gradient(to right, transparent, rgba($blue-300, 0.8));
`;
