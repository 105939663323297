import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import { Selector } from "../Selector";
import { FileExtensionContext, LoggedInUserContext, RecordTypeContext } from "../../App";
import { UserInfo } from "../Selector/Selector";

export const SelectorModal: React.FC = () => {
  const conversationTypeCells = useContext(RecordTypeContext);
  const allowedFileExtensions = useContext(FileExtensionContext);
  const loggedInUser = useContext(LoggedInUserContext);

  const userInfo: UserInfo = {
    // TODO: check if "workerID" can be undefined!
    id: Number(loggedInUser.idTokenClaims!.workerID),
    name: loggedInUser.name || loggedInUser.username,
  };

  return (
    <SelectorModalContainer>
      <Modal
        open
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={ModalBoxStyle}>
          <Selector
            loggedInUser={userInfo}
            allowedFileExtensions={allowedFileExtensions}
            conversationTypeCells={conversationTypeCells}
            defaultClient={{
              id: 1,
              firstName: "John",
              lastName: "Doe 1",
            }}
            defaultCandidate={{
              id: 2,
              firstName: "John",
              lastName: "Doe 2",
            }}
            defaultProject={{
              id: 2,
              name: "Default Project",
            }}
          />
        </Box>
      </Modal>
    </SelectorModalContainer>
  );
};

const SelectorModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 565,
  boxShadow: 24,
  p: 0,
  borderLeft: "20px solid",
  borderImage: "linear-gradient(to bottom, #018089, #E2F0F1) 1",

  "&:focus-visible": {
    outline: "none",
  },
};
