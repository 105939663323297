import React, { MouseEventHandler } from "react";
import styled from "@emotion/styled";
import { bodyM } from "../styles/typography";
import { textColors } from "../styles/colors";

export type TBaseCell = {
  headline: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  testId?: string;
  icon?: string;
  hasTitle?: boolean;
};

export type TBasicCell = TBaseCell & {
  subhead?: string;
  iconPosition?: "left" | "right";
  isHoverDisabled?: boolean;
};

export type TLinkCell = TBasicCell & {
  link?: string;
  target?: string;
};

export type TSelectableCell = TBasicCell & {
  /**
   * Color used to draw outer circle around the icon. Should be a
   * design system or color.ts value (primary, green, white, etc.).
   * This setting will determine the icon fill color as well.
   */
  iconCircleColor?: string;
  /**
   * If true, the state of the cell will be initially selected.
   */
  selected?: boolean;
  /**
   * Determines the location for the selected checkmark and item icon.
   * By default, the checkmark is displayed on the left and the
   * icon on the right. If true, these locations are swapped.
   */
  noteType?: boolean;
  fieldType?: string;
};

export type TValueCell = TBaseCell & {
  /**
   * Collapsed/expanded state of the cell that determined the
   * chevron to draw; either collapsed as arrow-right or expanded
   * as arrow down.
   */
  collapsed?: boolean;
  /**
   * Color used to draw outer circle around the icon. Should be a
   * design system or color.ts value (primary, green, white, etc.).
   * This setting will determine the icon fill color as well.
   */
  iconCircleColor?: string;
  /**
   * The value that is displayed on the right of the cell between the
   * icon (optional, on left) and chevron (optional, on right).
   */
  value?: string;
  isOptionalCellGroup?: boolean;
  isTypeField?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
};

export type TUnionCell = TSelectableCell & TValueCell & TLinkCell;

/**
 * @method BasicListCell
 * @description BasicListCell defines a single cell intended to exist within a list of elements. Can be found in `CellGroup`, where it is rendered as a list.
 * @param {string} headline - The title of the cell
 * @param {string} [subhead] - The optional subtitle of the header
 * @param {MouseEventHandler} [onClick] - An optional event that is fired when the cell is clicked.
 * @param {string} [testId] - testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button * @param {Function} [onTabSelect] -   onTabSelect is a callback function that is passed to the Tab Bar.
 * @param {string} [icon] -   An optional string that is placed into an img tag that renders the icon found on the right of the cell.
 * @param {boolean} [hasTitle] - A boolean check used to see if the parent list has a title. This affects the style of the list, and is primarily used in the `CellGroup` component. If you are unsure about its usage, leave this param undefined.
 * * @param {string} [isHoverDisabled]
 * @returns {ReactNode}
 */
export const BasicListCell: React.FC<TBasicCell> = ({
  headline,
  subhead,
  onClick,
  testId,
  icon,
  hasTitle,
  iconPosition = "right",
  isHoverDisabled = false,
}) => {
  return (
    <CellContainer
      hasTitle={hasTitle}
      hasSecondary={!!subhead}
      onClick={onClick}
      tabIndex={0}
      isHoverDisabled={isHoverDisabled}
    >
      <FlexContainer>
        {icon && iconPosition === "left" && (
          <img
            src={icon}
            alt={"Icon"}
            width={16}
            height={24}
            draggable={false}
          />
        )}
        <Details>
          <h4>{headline}</h4>
          {subhead && <p>{subhead}</p>}
        </Details>
      </FlexContainer>
      {icon && iconPosition !== "left" && (
        <img src={icon} alt={"Icon"} width={16} height={24} draggable={false} />
      )}
    </CellContainer>
  );
};

/**
 * @method BasicListCellWithLink
 * @description BasicListCellWithLink defines a single cell intended to exist within a list of elements and uses a link as its container. Can be found in `CellGroup`, where it is rendered as a list.
 * @param {string} headline - The title of the cell
 * @param {string} [subhead] - The optional subtitle of the header
 * @param {MouseEventHandler} [onClick] - An optional event that is fired when the cell is clicked.
 * @param {string} [testId] - testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button * @param {Function} [onTabSelect] -   onTabSelect is a callback function that is passed to the Tab Bar.
 * @param {string} [icon] -   An optional string that is placed into an img tag that renders the icon found on the right of the cell.
 * @param {boolean} [hasTitle] - A boolean check used to see if the parent list has a title. This affects the style of the list, and is primarily used in the `CellGroup` component. If you are unsure about its usage, leave this param undefined.
 * @param {string} link - A link that directs a user to a location.
 * @returns {ReactNode}
 */
export const BasicListCellWithLink: React.FC<TLinkCell> = ({
  headline,
  subhead,
  testId,
  icon,
  hasTitle,
  link,
  onClick,
  target,
}) => {
  return (
    <LinkCellContainer
      hasTitle={hasTitle}
      hasSecondary={!!subhead}
      tabIndex={0}
      href={link}
      target={target}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement> | undefined}
      aria-label={headline}
    >
      <Details>
        <h4>{headline}</h4>
        {subhead && <p>{subhead}</p>}
      </Details>
      {icon && (
        <img src={icon} alt={"Icon"} width={16} height={24} draggable={false} />
      )}
    </LinkCellContainer>
  );
};

/**
 * @method SelectableListCell
 * @description SelectableListCell defines a single cell intended to exist within a list of elements. that has the ability to visually represent a selected state. This state is currently represented through a checkmark. A variant of `BasicListCell`.
 * @param {string} headline - The title of the cell
 * @param {string} [subhead] - The optional subtitle of the header
 * @param {MouseEventHandler} [onClick] - An optional event that is fired when the cell is clicked.
 * @param {string} [testId] - testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button * @param {Function} [onTabSelect] -   onTabSelect is a callback function that is passed to the Tab Bar.
 * @param {string} [icon] -   An optional string that is placed into an img tag that renders the icon found on the right of the cell.
 * @param {string} [iconCircleColor] - Color used to draw outer circle around the icon. Should be a design system or color.ts value (primary, green, white, etc.).
 * @param {boolean} [hasTitle] - A boolean check used to see if the parent list has a title. This affects the style of the list, and is primarily used in the `CellGroup` component. If you are unsure about its usage, leave this param undefined.
 * @param {boolean} [selected] - A boolean check used to see if the cell should present a selected state. When true, a checkmark will be visible to the left of the cell.
 * @param {boolean} [noteType]
 * @param {string} [fieldType]
 * @returns {ReactNode}
 */
export const SelectableListCell: React.FC<TSelectableCell> = ({
  headline,
  subhead,
  selected,
  onClick,
  testId,
  icon,
  iconCircleColor,
  hasTitle,
  noteType = false,
  fieldType,
}) => {
  const childNode = (fieldType: string) => {
    switch (fieldType) {
      case "project":
        return (
          <>
            <Group>
              <CheckContainer>
                {selected && (
                  <img
                    src={"/icons/check-icon.svg"}
                    alt={"Check"}
                    width={16}
                    height={24}
                    draggable={false}
                  />
                )}
              </CheckContainer>
              <Details className={"cell-details"}>
                <h4>{headline}</h4>
                {subhead && <p>{subhead}</p>}
              </Details>
            </Group>
          </>
        );
      case "record":
        return (
          <>
            <Group>
              <CheckContainer>
                {selected && (
                  <img
                    src={"/icons/check-icon.svg"}
                    alt={"Check"}
                    width={16}
                    height={24}
                    draggable={false}
                  />
                )}
              </CheckContainer>
              <Details className={"cell-details"}>
                <h4>{headline}</h4>
              </Details>
            </Group>
          </>
        );
      // add company styling when company will be available
      case "company":
        return (
          <>
            <Group>
              <CheckContainer>
                {selected && (
                  <img
                    src={"/icons/check-icon.svg"}
                    alt={"Check"}
                    width={16}
                    height={24}
                    draggable={false}
                  />
                )}
              </CheckContainer>
              <Details className={"cell-details"}>
                <h4>{headline}</h4>
                {subhead && <p>{subhead}</p>}
              </Details>
            </Group>
            {icon &&
              (iconCircleColor ? null : (
                <img
                  src={icon}
                  alt={"Check"}
                  width={16}
                  height={24}
                  draggable={false}
                />
              ))}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <CellContainer
      className={"cell-item"}
      hasTitle={hasTitle}
      hasSecondary={!!subhead}
      selected={selected}
      onClick={onClick}
      tabIndex={0}
      noteType={noteType}
      fieldType={fieldType}
    >
      {childNode(fieldType ?? "")}
    </CellContainer>
  );
};

/**
 * @method ValueListCell
 * @description ValueListCell defines a single cell intended to exist within a list of elements. that
 * has a left-margin headline (label) and right-margin value. The icon is displayed to the left of the
 * value field. An optional chevon is displayed on the right to indicate the opened/closed state.
 * A variant of `BasicListCell`.
 * Refer to the [Figma Design example](https://www.figma.com/file/w8jiwgbF14ES6Ilezq5fy0/Zendai---Component-Library?node-id=114-3967&t=ljwq9f5oVJlwyHLO-4)
 * @param {string} label - The title of the cell
 * @param {string} [value] - The optional subtitle of the header
 * @param {MouseEventHandler} [onClick] - An optional event that is fired when the cell is clicked.
 * @param {string} [testId] - testId interpolates its value in multiple test ids. Ex. my-test-id-searchfield, my-test-id-button * @param {Function} [onTabSelect] -   onTabSelect is a callback function that is passed to the Tab Bar.
 * @param {string} [icon] -   An optional string that is placed into an img tag that renders the icon found on the right of the cell.
 * @param {string} [iconCircleColor] -   Color used to draw outter circle around the icon. Should be a define from the color.ts (primary, green, etc.). This setting will determine the icon fill color as well.
 * @param {boolean} [hasTitle] - A boolean check used to see if the parent list has a title. This affects the style of the list, and is primarily used in the `CellGroup` component. If you are unsure about its usage, leave this param undefined.
 * @param {boolean} [collapsed] - Defines the collapsed (chevron right) or expanded (chevron down) state of the cell. The defealt undefined state will hide the chevron from view.
 * @returns {ReactNode}
 */
export const ValueListCell: React.FC<TValueCell> = ({
  headline,
  value,
  onClick,
  testId,
  hasTitle,
  collapsed,
  isOptionalCellGroup = false,
  isTypeField,
}) => {
  const isTypeFieldWithValue = isTypeField && value;
  const typeValueStyle = isTypeFieldWithValue
    ? { fontSize: "14px", color: "#000000a3" }
    : {};

  return (
    <ValueCellContainer
      className={"value-cell-container"}
      hasTitle={hasTitle}
      hasSecondary={false}
      onClick={onClick}
      tabIndex={0}
      isEditing={collapsed}
      isOptionalCellGroup={isOptionalCellGroup}
    >
      {!isTypeFieldWithValue && (
        <Label className={"value-cell-label"}>
          <h4>{headline}</h4>
        </Label>
      )}

      <Content>
        <Details className="valueList">
          <h4 style={typeValueStyle}>{value}</h4>
        </Details>
        {collapsed !== undefined && (
          <StateLightColorImage
            src={
              collapsed ? "/icons/chevron-right.svg" : "/icons/angle-down.svg"
            }
            alt={"Icon"}
            width={12}
            height={18}
            draggable={false}
          />
        )}
      </Content>
    </ValueCellContainer>
  );
};

/**
 * @method LabelCell
 * @description LabelCell is a Cell that contains the title of a label group. These can be found optionally in `CellGroup`s.
 * @param {string} title - The label/title that is most often used to represent a label group.
 * @returns {ReactNode}
 */
export const LabelCell = ({ title }: { title: string }) => {
  return (
    <LabelContainer
      data-cy={"cell-label"}
      hasSecondary={false}
      selected={false}
    >
      <CellTitle data-cy={"cell-label"}>{title}</CellTitle>
    </LabelContainer>
  );
};

const coreCellContainerStyles = `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  height: 46px;
  gap: 6px;
  
  &:hover {
    // TODO: background - list disabled
    background: #efefef;
  }


`;

const CellContainer = styled.div<{
  selected?: boolean;
  hasSecondary: boolean;
  hasTitle?: boolean;
  noteType?: boolean;
  isHoverDisabled?: boolean;
  fieldType?: string;
}>`
  ${coreCellContainerStyles};

  ${({ fieldType }) =>
    fieldType === "record" &&
    `height : 30px;
  `};

  ${({ isHoverDisabled }) =>
    isHoverDisabled &&
    `
    &:hover {
    background: white;
  }

  cursor: default;
  color : #C1BBB4 
  `};

  ${({ noteType }) =>
    noteType &&
    `flex-direction: row-reverse;
  gap : 15px;
  border-bottom : 0.5px solid #C6C7C8
  `};

  ${({ hasSecondary }) => hasSecondary && "height: 64px"};

  ${({ hasTitle }) =>
    hasTitle &&
    `
    &:nth-of-type(2) {
      border-top: none;
    }
  `}
`;

const LinkCellContainer = styled.a<{
  selected?: boolean;
  hasSecondary: boolean;
  hasTitle?: boolean;
}>`
  ${coreCellContainerStyles};
  text-decoration: none;
  color: inherit;
  flex-direction: row-reverse
    ${({ hasSecondary }) => hasSecondary && "height: 64px"};

  ${({ hasTitle }) =>
    hasTitle &&
    `
    &:nth-of-type(2) {
      border-top: none;
    }
  `}
`;

const ValueCellContainer = styled(CellContainer)<{
  isEditing?: boolean;
  isOptionalCellGroup?: boolean;
}>`
  // TODO: Zendai/state/light/state5
  color: rgba(0, 0, 0, 0.64);
  gap: 12px;
  position: relative;
  ${({ isEditing }) => !isEditing && "cursor: default !important;"};

  ${({ isOptionalCellGroup }) =>
    isOptionalCellGroup && "border-bottom: 1px solid #dbd5cd;"}

  &:before {
    content: "";
    position: absolute;
    margin-top: -45px;
    width: 100%;
  }

  > div:first-of-type {
    padding-left: 16px;
    color: #181818;
    flex-grow: 1;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
`;

const Details = styled.div`
  text-overflow: ellipsis;

  // Using valueList class selector so styles only apply
  // to the ValieListCell.
  &.valueList {
    // Do not allow flexbox to expand beyond viewport.
    min-width: 0;

    > h4 {
      // Prevent text wrapping and enable ellipsis handling of overflow.
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  > h4 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  > p {
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${bodyM};
    color: ${textColors.textOnBgMedium};
  }
`;

const Label = styled.div`
  text-overflow: ellipsis;

  > h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`;

const CheckContainer = styled.span`
  min-width: 18px;
`;

const CellTitle = styled.h3`
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const LabelContainer = styled(CellContainer)`
  cursor: auto;

  &:hover {
    background: inherit;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

const StateLightColorImage = styled("img")``;

const FlexContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;
