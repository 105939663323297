/**
 * TIconName defines names for most of the Font Awesome icons used by the
 * application and is used by the InsetIcon class for specify the
 * icon to be displayed. Also used by the IconFileLookup to convert
 * the TIconName value into the path for that icon.
 */
export type TIconName =
  | "angleDown"
  | "angleLeft"
  | "angleRight"
  | "arrowRotateLeft"
  | "arrowRotateRight"
  | "bold"
  | "bookSparkles"
  | "bookUser"
  | "books"
  | "briefcase"
  | "building"
  | "clockRotateLeft"
  | "commentLines"
  | "handshakeSimple"
  | "indent"
  | "italic"
  | "listOrdered"
  | "list"
  | "outdent"
  | "quoteLeft"
  | "person"
  | "ttyAnswer"
  | "underline"
  | "whiskeyGlassIce"
  | "wineGlass"
  | "chevronRight"
  | "penToSquare"
  | "exmark"
  | "checkmark"
  | "bigClose"
  | "cardsDark"
  | "listDark"
  | "note"
  | "personOutline"
  | "star";

/**
 * By using the icon name, the path to the icon file can be located.
 * Example: IconFileLoopup[TIconName.briefcase] returns the path to
 * the briefcase icon file.
 */
export const IconFileLookup: Record<TIconName, string> = {
  angleDown: "/icons/angle-down.svg",
  angleLeft: "/icons/angle-left.svg",
  angleRight: "/icons/angle-right.svg",
  arrowRotateLeft: "/icons/arrow-rotate-left.svg",
  arrowRotateRight: "/icons/arrow-rotate-right.svg",
  bold: "/icons/bold.svg",
  bookSparkles: "/icons/book-sparkles.svg",
  bookUser: "/icons/book-user.svg",
  books: "/icons/books.svg",
  briefcase: "/icons/briefcase.svg",
  building: "/icons/building.svg",
  clockRotateLeft: "/icons/clock-rotate-left.svg",
  commentLines: "/icons/comment-lines.svg",
  handshakeSimple: "/icons/handshake-simple.svg",
  indent: "/icons/indent.svg",
  italic: "/icons/italic.svg",
  listOrdered: "/icons/list-ordered.svg",
  list: "/icons/list.svg",
  outdent: "/icons/outdent.svg",
  quoteLeft: "/icons/quote-left.svg",
  person: "/icons/person-icon.svg",
  ttyAnswer: "/icons/tty-answer.svg",
  underline: "/icons/underline.svg",
  whiskeyGlassIce: "/icons/whiskey-glass-ice.svg",
  wineGlass: "/icons/wine-glass.svg",
  chevronRight: "/icons/chevron-right.svg",
  bigClose: "/icons/big-close.svg",
  penToSquare: "/icons/pen-to-square.svg",
  exmark: "/icons/exmark.svg",
  checkmark: "/icons/checkmark.svg",
  cardsDark: "/icons/cards-icon-dark.svg",
  listDark: "/icons/list-icon-dark.svg",
  note: "/icons/note-icon-outlined.svg",
  personOutline: "/icons/person-icon-outlined.svg",
  star: "/icons/star.svg",
};
