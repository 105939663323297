import * as React from "react";
import { Contact } from ".";
import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";
import { Avatar } from "../Avatar";

type ContactDropdownItemProps = {
  onClick: (item: Contact) => void;
  key: string | number;
  item: Contact;
  cssOverride?: string;
};

export const ContactDropdownItem = ({
  item,
  key,
  onClick,
  cssOverride,
}: ContactDropdownItemProps) => {
  const { name, company, position, firstName, lastName, isBlocked } = item;

  const extraData = {
    avatar: null,
    fallbackIcon: "/icons/person-icon.svg",
    relationship: "",
  };

  let concatName = name ?? `${firstName} ${lastName}`;

  return (
    <StyleCustomComponent
      disableRipple
      key={key}
      onClick={() => onClick(item)}
      cssOverride={cssOverride ?? ""}
    >
      {/* {type === "search-item" && (
        <AvatarContainer>
          <BadgeContainer>
            {isBlocked && (
              <CircleContainer>
                <StyledImage src={"/icons/lock-icon.svg"} width={10} height={10} />
              </CircleContainer>
            )}
          </BadgeContainer>

          <Avatar
            image={extraData.avatar!}
            icon={!extraData.avatar ? extraData.fallbackIcon : undefined}
          />

          <BadgeContainer>
            {!!extraData.relationship && (
              <AvatarChip>
                <StyledImage src={"/icons/relationship-b.svg"} width={10} height={10} />
              </AvatarChip>
            )}
          </BadgeContainer>
        </AvatarContainer>
      )} */}
      <StyledDiv>
        <BoldSpan>{concatName}</BoldSpan>
        <StyledSpan>{position}</StyledSpan>
        <StyledSpan>{company}</StyledSpan>
      </StyledDiv>
    </StyleCustomComponent>
  );
};

const BadgeContainer = styled.div`
  width: 10px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const StyledSpan = styled.span`
  color: #919493;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyleCustomComponent = styled(ListItemButton)<{ cssOverride: string }>`
  span {
    font-size: 0.875rem;
  }
  color: #57595b;
  font-family: "Messina Sans";
  font-weight: 400;
  line-height: 1.25rem;
  border-bottom: 0.5px solid #c6c7c8;

  /* border-left-width: 4px;
  border-style: solid;
  border-color: #ffffff;
  border-bottom: 1px #dbd5cd solid; */

  /* :first-child {
    border-top: 1px solid #dbd5cd;
  } */

  min-height: 44px;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;

  &:hover {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 
    
    */

    background-color: #efefef;
    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;

    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:focus {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 
    */
    background-color: #efefef;
    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:active {
    color: #57595b;

    /* border-left-width: 4px;
    border-style: solid;
    border-color: #375f86; 

    */
    background-color: #efefef;
    border-bottom-color: #dbd5cd;
    border-top-color: #dbd5cd;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  ${(props) => props.cssOverride}
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CircleContainer = styled.div`
  border: 1px solid #ffffff;
  width: 14px;
  min-width: 14px;
  height: 14px;
  background: #181818;
  border-radius: 50%;
  left: 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const AvatarChip = styled(CircleContainer)`
  border: none;
  background: #d4d4d5;
  left: -9px;
  width: 10px;
`;
