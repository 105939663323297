import React from "react";

export const LogoCapture = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 792 576"
    height="125px"
  >
    <g id="avKjPA.tif">
      <g>
        <path
          className="cls-3"
          d="M197.4,309.2c.2,1,0,1.9,0,2.9,0,9.6,0,19.1,0,28.7,0,10.9-8,20.5-18.7,22.7-1.6.3-3.1.5-4.7.5-18.2,0-36.4,0-54.5,0-8.8,0-17.6-1.7-25.8-5.1-21.7-9-36.5-24.4-44.3-46-1.6-4.3-2.7-8.8-3.4-13.3s-1.1-11-.8-16.5c1.3-19.1,8.7-35.5,22.1-49,11.5-11.5,25.5-18.6,41.7-21.4s7.8-.9,11.8-.9c17.8,0,35.6,0,53.3,0,11.1,0,19.7,7.3,22.4,16.7.5,1.9.8,3.9.8,5.9,0,8.8,0,17.5,0,26.3,0,.7-.2,3.3-.2,3.5-.8,5.2-3.1,9.6-7,13.1-2.4,2.2-5.1,3.8-8.3,4.5-2,.4-4,.5-6,.5-5.5,0-10.9,0-16.4,0-13,0-25.9,0-38.9,0-3.3,0-5.1,3-3.2,7.4,19.5,0,39.1,0,58.6,0,4.1,0,7.9,1.2,11.3,3.3,4.3,2.6,6.9,6.6,8.6,11.2.6,1.6,1,3.2,1.3,4.8,0,0,0,0,0,0ZM101,312.5c0,1.2,0,9.7,0,14.6,0,3.2,2.4,5.6,5.6,5.6,9.2,0,18.4,0,27.6,0s19.3,0,28.9,0,4.4-1.9,4.4-4.3c0-6.4,0-12.8,0-19.2s0-.3,0-.5c-1-3.1-3.1-4.4-6-4.4-18,0-36.1,0-54.1,0-2.1,0-4.2-.5-6.1-1.3-1.8-.8-2.7-2.4-2.7-4.3,0-6.2,0-12.4,0-18.7s0-1.4.1-1.9c.2-1.4.5-2.7,1.2-3.9.4-.8,1.1-1.4,2-1.6,2.3-.8,4.7-1.1,7.1-1.1,17.6,0,35.2,0,52.9,0,3.4,0,5.8-2.4,5.8-5.8v-16.3c.3-3.6-2.2-6.3-5.7-6.3-18.3,0-36.7,0-55,0-.4,0-.9,0-1.3.1-2.6.5-4.5,2.8-4.5,5.5,0,5.1,0,10.1,0,15.2,0,.6,0,1.1-.1,1.7-.4,2.7-1.7,4.4-4.5,5-2.7.6-5.4.9-8.1.9-4.2,0-8.4,0-12.6,0-3.4,0-6.1,2.8-5.6,6.3v20.1c-.5,3.5,2.2,6.3,5.5,6.3,4.5,0,9,0,13.4,0,2.5,0,6.3.6,8.8,2.9"
          stroke-width="0px"
          fill="#00838a"
        />
        <path
          className="cls-2"
          d="M197.5,309.2c0,0,0,0,0,0,0,0,0,0,0,0Z"
          fill="#a7cbcf"
          stroke-width="0px"
        />
      </g>
    </g>
    <text className="cls-1" transform="translate(228.8 322.9)">
      <tspan
        x="0"
        y="0"
        fill="#231f20"
        fontFamily="MessinaSans-SemiBold, 'Messina Sans'"
        font-size="100.9px"
        font-weight={600}
      >
        CAPTURE
      </tspan>
    </text>
    <ellipse
      className="cls-3"
      cx="91.3"
      cy="317.1"
      rx=".3"
      ry=".1"
      stroke-width="0px"
      fill="#00838a"
    />
  </svg>
);
