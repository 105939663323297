import * as React from "react";
import { useEffect } from "react";
import { Contact, MentionOptions, PossibleMentionType } from "../Selector";

// Hook to handle clicks outside of a specified element
export function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  outsideClickHandler: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        outsideClickHandler();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

// Function to move the cursor to the end of a contenteditable div
export const moveCursorToEnd = (textareaRef: React.RefObject<HTMLDivElement>) => {
  const range = document.createRange();
  const selection = window.getSelection();
  const node = textareaRef.current;

  if (node && selection) {
    range.selectNodeContents(node);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

// Constants for the maximum number of search results and the style for mentions
export const MAX_SEARCH_RESULTS = 30;
export const mentionStyle =
  "font-weight:bold;color:blue;text-decoration:underline;cursor:pointer";

// Function to filter and set mention results based on the opening character
export const setResults = (
  mentionOptions: MentionOptions[],
  currectOpeningCharacter: string
) => {
  const currentReturn = mentionOptions.find(
    (option: MentionOptions) => option.openingCharacter === currectOpeningCharacter
  )?.resultData;

  const filteredCurrentReturn = currentReturn?.slice(0, MAX_SEARCH_RESULTS);

  return filteredCurrentReturn;
};

// Function to get the caret position within a contenteditable div
export const getCaretCharacterOffsetWithin = (element: HTMLElement) => {
  let caretOffset = 0;
  const sel = window.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  }
  return caretOffset;
};

// Function to update the caret position within a contenteditable div
export const updateCaretPosition = (element: HTMLElement, caretOffset: number) => {
  const range = document.createRange();
  const sel = window.getSelection();
  let currentOffset = 0;

  if (sel) {
    for (let node of element.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent!.length;

        if (currentOffset + textLength >= caretOffset) {
          range.setStart(node, caretOffset - currentOffset);
          break;
        }

        currentOffset += textLength;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementLength = node.textContent!.length;

        if (currentOffset + elementLength >= caretOffset) {
          range.setStart(node, 0);
          break;
        }

        currentOffset += elementLength;
      }
    }

    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

// Function to get the line number of the caret within a contenteditable div
export const getCaretLine = (element: HTMLElement) => {
  const sel = window.getSelection();
  if (!sel || sel.rangeCount === 0) return 0;

  const range = sel.getRangeAt(0);
  const parentDiv =
    range.startContainer.nodeType === 3
      ? range.startContainer.parentElement
      : range.startContainer;

  const divs = Array.from(element.querySelectorAll("div"));
  const line = divs.findIndex((div) => div === parentDiv);

  return line + 1; // Lines are 1-indexed
};

// Function to check if the space key was pressed
export const isSpacePressed = (event: KeyboardEvent) => {
  return event.key === " " || event.code === "Space" || event.keyCode === 32;
};

// Type guard to check if an object is of type Contact
export function isContact(obj: any): obj is Contact {
  return obj && typeof obj === "object" && "firstName" in obj && "lastName" in obj;
}

// New function to clean up the mention lists
export const cleanUpMentionListsFromDeletedMentions = (text: string, options: MentionOptions[]) => {
  options.forEach((option) => {
    const { openingCharacter, mentionList } = option;
    const regex = new RegExp(`${openingCharacter}(\\d+)\\/${openingCharacter}`, "g");

    const mentionsInText = new Set<string>();
    let match;

    while ((match = regex.exec(text)) !== null) {
      mentionsInText.add(match[1]);
    }

    Object.keys(mentionList).forEach((id) => {
      if (!mentionsInText.has(id)) {
        delete mentionList[id];
      }
    });
  });
};

// Function to create a mention span element
export const createMentionSpan = (
  mention: PossibleMentionType,
  openingCharacter: string
) => {
  const span = document.createElement("span");
  span.setAttribute("contentEditable", "false");
  span.setAttribute("data-opening-character", openingCharacter);
  span.setAttribute("data-id", `${mention.id}`);

  if (isContact(mention)) {
    span.textContent = `${mention.firstName} ${mention.lastName}`;
  } else {
    span.textContent = mention.name ?? "";
  }

  // Apply inline styles
  span.setAttribute(
    "style",
    `
    display: inline-block;
    padding: 0px 12px;
    border: 1px solid #29527a;
    border-radius: 4px;
    background-color: #EAEEF2;
    color: #375F86;
    height: fit-content;
    fontSize: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    margin: 2px 4px;
  `
  );

  return span.outerHTML;
};

// Function to replace mention names with IDs in the text
export const replaceNamesWithIds = (text: string, options: MentionOptions[]) => {
  options.forEach((option) => {
    const { openingCharacter, mentionList } = option;
    Object.values(mentionList).forEach((mention) => {
      const mentionName = isContact(mention)
        ? `${mention.firstName} ${mention.lastName}`
        : mention.name ?? "";
      const regex = new RegExp(`\\b${mentionName}\\b`, "g");
      text = text.replace(regex, `${openingCharacter}${mention.id}/${openingCharacter}`);
    });
  });
  return text;
};

// Replace mention IDs with names in the text
export const replaceMentionIdsWithNames = (text: string, options: MentionOptions[]) => {
  options.forEach((option) => {
    const { openingCharacter, mentionList } = option;
    const regex = new RegExp(`${openingCharacter}(\\d+)\\/${openingCharacter}`, "g");

    text = text.replace(regex, (match, id) => {
      const mention = mentionList[id];
      if (mention) {
        const mentionName = isContact(mention)
          ? `${mention.firstName} ${mention.lastName}`
          : mention.name ?? "";
        return `${mentionName}`; // Replace ID with mention name
      }
      return match;
    });
  });
  return text;
};

// Function to revert mention span elements to placeholders in HTML
export const revertSpansToPlaceholders = (html: string, options: MentionOptions[]) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  options.forEach((option: MentionOptions) => {
    const spans = doc.querySelectorAll(
      `span[data-opening-character="${option.openingCharacter}"]`
    );

    spans.forEach((span) => {
      const mentionId = span.getAttribute("data-id");
      if (mentionId) {
        const placeholder = `${option.openingCharacter}${mentionId}/${option.openingCharacter}`;
        const textNode = document.createTextNode(placeholder);
        span.replaceWith(textNode);
      }
    });
  });

  return doc.body.innerHTML;
};

// Function to debounce another function, delaying its execution
export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function (this: any, ...args: Parameters<T>) {
    const context = this;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  } as T;
}

export const replaceBrTagWithEmptyStringForNames = (text: string) => {
  return text.replace(/<br>/g, "");
};

export function replaceLastOccurrence(
  comment: string,
  nameToReplace: string,
  idToReplace: string,
  caretPosition: number
) {
  const beforeCaret = comment.substring(0, caretPosition);

  const lastIndex = beforeCaret.lastIndexOf(nameToReplace);

  if (lastIndex === -1) {
    return comment;
  }

  const beforeMatch = comment.substring(0, lastIndex);
  const afterMatch = comment.substring(lastIndex + nameToReplace.length);

  return beforeMatch + idToReplace + afterMatch;
}
