import React from "react";
import styled from "@emotion/styled";

type Props = {
  text: string;
  action?: {
    text: string;
    onClick: () => void;
  };
};

const GeneralErrorView: React.FC<Props> = ({ text, action }) => {
  return (
    <Container>
      <h2>{text}</h2>
    </Container>
  );
};

export default GeneralErrorView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  max-width: 550px;
  margin: 0 auto;

  > h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding: 0 32px;
    margin-bottom: 28px;
  }
`;
