export const OPEN_MODAL = "Open modal";
export const NO_FILES_ARE_SELECTED = "No files are selected";
export const SELECT_A_FILE = "Select a file...";
export const ERROR_LOADING =
  "Error loading. Please reach out to support or try again.";
export const PLEASE_UPLOAD_A_FILE = "Please upload a file";
export const IS_NOT_VALID_FILE_EXTENSION = " is not a valid file extension.";
export const MISSING_REQUIRED_INFORMATION = "Missing required information";
export const CREATED_ON = "Created on";
export const AUTHOR = "Author";
export const TYPE = "Type";
export const OPTIONAL = "Optional";
export const REQUIRED = "Required";
export const COMPANY = "Company";
export const EXECUTIVE = "Executive";
export const PROJECT = "Project";
export const COMMENTS = "Comments";
export const CLIENT = "Client";
export const CANDIDATE = "Candidate";

export const SELECT_FINAL_DOCUMENT_TYPE = "Select Final Document Type";
export const SELECT_FINAL_DOCUMENT = "Select Final Document";

export const START_TYPING_TO_SEARCH_FOR_COMPANIES =
  "Start typing to search for companies";
export const FIND_A_COMPANY = "Find a Company";
export const SELECT_A_COMPANY = "Select a Company";

export const START_TYPING_TO_SEARCH_FOR_AN_EXECUTIVE =
  "Start typing to search for an executive";
export const FIND_AN_EXECUTIVE = "Find an Executive";
export const SELECT_A_EXECUTIVE = "Select an Executive";

export const START_TYPING_TO_SEARCH_FOR_A_CLIENT =
  "Start typing to search for a client";
export const FIND_A_CLIENT = "Find a Client";
export const SELECT_A_CLIENT = "Select a Client";

export const START_TYPING_TO_SEARCH_FOR_A_CANDIDATE =
  "Start typing to search for a candidate";
export const FIND_A_CANDIDATE = "Find a Candidate";
export const SELECT_A_CANDIDATE = "Select a Candidate";

export const START_TYPING_TO_SEARCH_FOR_PROJECTS =
  "Start typing to search for projects";
export const FIND_A_PROJECT = "Find a Project";
export const SELECT_A_PROJECT = "Select a Project";

export const SELECT_RECORD_TYPE = "Select record type";

export const SEND_TO_ZENDAI = "Send to Zendai";
export const CANCEL = "Cancel";
export const UPLOADING = "Uploading...";
export const SIGN_OUT = "Sign out";

export const ON_BEHALF_OF = "On Behalf Of";
