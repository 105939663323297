import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { device, white } from "../../styles";
import { CellGroup, CellGroupContainer } from "../CellGroup";
import { IconFileLookup } from "../../utils/icons";
import { FileUploader } from "../FileUploader";
import { SelectorEditRecordType } from "../SelectorEditRecordType";
import {
  stateMachine,
  initContext,
  EVENT,
  NOTE_EVENT_TYPE,
  STATE,
  InitialSelectorData,
  getFileLimit,
} from "./selectorMachine";
import {
  Button,
  BUTTON_ACTION,
  BUTTON_SIZE,
  DragNDrop,
  TextareaCapture,
} from "@ez/design-system";
import {
  COMPANY,
  PROJECT,
  SEND_TO_ZENDAI,
  UPLOADING,
  NO_FILES_ARE_SELECTED,
  SELECT_FINAL_DOCUMENT_TYPE,
  CLIENT,
  CANDIDATE,
  AUTHOR,
} from "../utils/i18n";
import { FEEDBACK_TYPE, FeedbackDisplay } from "./FeedbackDisplay";
import { useMachine } from "@xstate/react";
import { postNote } from "../../services/api/note";
import { Company, Contact, MentionItem, Note, NoteType, Project, TargetType } from ".";

import { EndSessionRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  useDataProductSearchContact,
  useDataProductSearchCompany,
  useDataProductSearchProject,
  searchDataProductContacts,
  searchDataProductCompanies,
  searchDataProductProjects,
  searchDataProductOBOUsers,
  DPContact,
  DPCompany,
  DPProject,
  DPOPOUser,
} from "@ez/design-system-data-linker";
import { loginRequest } from "../../authConfig";
import { SelectorMenu } from "../SelectorMenu";
import { Icon } from "../Icon";
import { ContactDropdownItem } from "./ContactDropdownItem";
import { ProjectDropdownItem } from "./ProjectDropDownItem";
import { CompanyDropdownItem } from "./CompanyDropDownItem";
import { getPrimaryAndSecondaryPerson } from "../../utils/helpers";
import { Selector as SelectorComponent, CELL_TYPE } from "@ez/design-system";
import { TUnionCell } from "../ListCell";
import { Mention } from "../Mention/Mention";
import { FILE_SIZE_LIMIT } from "../../services/constants";
import ModalTitle from "../ModalTitle";

/**
 * Returns a new string with the person's combined first and
 * last name. Will handle cases where just the user's first
 * or last name is provided.
 * If either are provided, undefined is returned.
 * @returns New string with the user's full name.
 */
const displayName = (firstName?: string, lastName?: string) => {
  return firstName && lastName
    ? [firstName, lastName].join(" ")
    : firstName
    ? firstName
    : lastName
    ? lastName
    : undefined;
};

export type UserInfo = {
  id: number;
  name: string;
};

export type SelectorProps = {
  conversationTypeCells?: NoteType[];
  allowedFileExtensions?: string[];
  loggedInUser: UserInfo;
  defaultClient?: Contact;
  defaultProject?: Project;
  defaultCandidate?: Contact;
  defaultCompany?: Company;
};

export const Selector: React.FC<SelectorProps> = ({
  conversationTypeCells,
  allowedFileExtensions,
  loggedInUser,
  defaultClient,
  defaultCandidate,
  defaultCompany,
  defaultProject,
}) => {
  const isMentionFunctionalityEnabled =
    process.env.REACT_APP_ENABLE_MENTION_FUNCTIONALITY === "true";

  const [contactSearchTerm, setContactSearchTerm] = useState<string>("");
  const [contactData] = useDataProductSearchContact(contactSearchTerm);
  const [contactMentionList, setContactMentionList] = useState<{
    [key: string]: Contact;
  }>({});

  const [companySearchTerm, setCompanySearchTerm] = useState<string>("");
  const [companyData] = useDataProductSearchCompany(companySearchTerm);
  const [companyMentionList, setCompanyMentionList] = useState<{
    [key: string]: Company;
  }>({});

  const [projectSearchTerm, setProjectSearchTerm] = useState<string>("");
  const [projectData] = useDataProductSearchProject(projectSearchTerm);
  const [projectMentionList, setProjectMentionList] = useState<{
    [key: string]: Project;
  }>({});

  const initialSelectorData: InitialSelectorData = {
    conversationTypeCells: conversationTypeCells || [],
    allowedFileExtensions: allowedFileExtensions || [],
    loggedInUser,
    fileSizeLimit: getFileLimit(),
    defaultClient,
    defaultCandidate,
    defaultCompany,
    defaultProject,
  };

  const [state, send, service] = useMachine(stateMachine, {
    context: {
      ...initContext,
      conversationTypeCells: initialSelectorData.conversationTypeCells,
      allowedFileExtensions: initialSelectorData.allowedFileExtensions,
      loggedInUser: initialSelectorData.loggedInUser,
      note: {
        ...initContext.note,
        onBehalfOf: {
          id: initialSelectorData.loggedInUser.id,
          firstName: initialSelectorData.loggedInUser.name,
        },
        client: defaultClient || { id: null },
        candidate: defaultCandidate || { id: null },
        company: defaultCompany || { id: null },
        project: defaultProject || { id: null },
      },
      initialData: initialSelectorData,
    },
  });

  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const handleSignOut = () => {
    const logoutRequest: EndSessionRequest = {
      account: activeAccount,
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  };

  // Logging XState
  useEffect(() => {
    const subscription = service.subscribe((_) => {});

    return subscription.unsubscribe;
  }, [service]);

  const note = state.context.note as Note;

  const cells = {
    cellClient: {
      type: CELL_TYPE.CLIENT,
      onSearch: async (query: string): Promise<DPContact[][]> => {
        let [contacts] = await searchDataProductContacts(query);
        return [contacts];
      },
      onClick: () => {
        send(EVENT.BEGIN_SELECT, { data: NOTE_EVENT_TYPE.CLIENT });
      },
      onSelect: (contact: Contact) => {
        send(EVENT.END_SELECT, {
          data: contact,
          dataType: NOTE_EVENT_TYPE.CLIENT,
        });
      },
      onCancel: () => {
        send(EVENT.END_SELECT, { data: null });
      },
      headline: CLIENT,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.CLIENT,
      value: displayName(note.client.firstName, note.client.lastName) || "",
      icon: note.client.id ? note.client.id.toString() : undefined,
      iconCircleColor: "red",
      isRequired: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Client === "Required" && !note.client.id
        : false,
      isHidden: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Client === "Hidden"
        : false,
      isDisabled: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Client === "Future release"
        : false,
    },
    cellCandidate: {
      type: CELL_TYPE.CANDIDATE,
      onSearch: async (query: string): Promise<DPContact[][]> => {
        let [contacts] = await searchDataProductContacts(query);
        return [contacts];
      },
      onSelect: (contact: Contact) => {
        send(EVENT.END_SELECT, {
          data: contact,
          dataType: NOTE_EVENT_TYPE.CANDIDATE,
        });
      },
      onCancel: () => {
        send(EVENT.END_SELECT, { data: null });
      },
      headline: CANDIDATE,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.CANDIDATE,
      onClick: () => send(EVENT.BEGIN_SELECT, { data: NOTE_EVENT_TYPE.CANDIDATE }),
      value: displayName(note.candidate.firstName, note.candidate.lastName) || "",
      icon: note.candidate.id ? note.candidate.id.toString() : undefined,
      iconCircleColor: "red",
      isRequired: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Candidate === "Required" &&
          !note.candidate.id
        : false,
      isHidden: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Candidate === "Hidden"
        : false,
      isDisabled: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Candidate === "Future release"
        : false,
    },
    cellCompany: {
      type: CELL_TYPE.COMPANY,
      onSearch: async (query: string): Promise<DPCompany[][]> => {
        let [companies] = await searchDataProductCompanies(query);
        return [companies];
      },
      onSelect: (company: Company) => {
        send(EVENT.END_SELECT, {
          data: company,
          dataType: NOTE_EVENT_TYPE.COMPANY,
        });
      },
      onCancel: () => {
        send(EVENT.END_SELECT, { data: null });
      },
      headline: COMPANY,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.CONTACT,
      onClick: () => send(EVENT.BEGIN_SELECT, { data: NOTE_EVENT_TYPE.COMPANY }),
      value: note.company.name,
      icon: note.company.name ? IconFileLookup.briefcase : undefined,
      iconCircleColor: "green",
      testId: "company",
      isRequired: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Company === "Required" && !note.company.id
        : false,
      isHidden: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Company === "Hidden"
        : false,
      isDisabled: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Company === "Future release"
        : false,
    },
    cellProject: {
      type: CELL_TYPE.PROJECT,
      onSearch: async (query: string): Promise<DPProject[][]> => {
        let [projects] = await searchDataProductProjects(query);
        return [projects];
      },
      onSelect: (project: Project) => {
        send(EVENT.END_SELECT, {
          data: project,
          dataType: NOTE_EVENT_TYPE.PROJECT,
        });
      },
      onCancel: () => {
        send(EVENT.END_SELECT, { data: null });
      },
      headline: PROJECT,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.CONTACT,
      onClick: () => send(EVENT.BEGIN_SELECT, { data: NOTE_EVENT_TYPE.PROJECT }),
      value: note.project.name ? note.project.name : "",
      testId: "project",
      isRequired: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Project === "Required" && !note.project.id
        : false,
      isHidden: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Project === "Hidden"
        : false,
      isDisabled: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.Project === "Future release"
        : false,
    },
    cellRecordType: {
      headline: note.type.label ? "" : SELECT_FINAL_DOCUMENT_TYPE,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.CONTACT,
      onClick: () => send(EVENT.BEGIN_SELECT, { data: NOTE_EVENT_TYPE.TYPE }),
      value: note.type.label ? note.type.label : "",
      icon: undefined,
      iconCircleColor: white,
      testId: "note-type",
      isRequired: note.type.label ? false : true,
      isHidden: false,
    },
    cellOnBehalfOf: {
      type: CELL_TYPE.ON_BEHALF_OF,
      onSearch: async (query: string): Promise<DPOPOUser[][]> => {
        let [oboUsers] = await searchDataProductOBOUsers(query);
        return [oboUsers];
      },
      onSelect: (contact: Contact) => {
        send(EVENT.END_SELECT, {
          data: contact,
          dataType: NOTE_EVENT_TYPE.ON_BEHALF_OF,
        });
      },
      onCancel: () => {
        send(EVENT.END_SELECT, { data: null });
      },
      headline: AUTHOR,
      collapsed: state.context.editorType !== NOTE_EVENT_TYPE.ON_BEHALF_OF,
      onClick: () => {
        send(EVENT.BEGIN_SELECT, {
          data: NOTE_EVENT_TYPE.ON_BEHALF_OF,
        });
      },
      value: displayName(note.onBehalfOf.firstName, note.onBehalfOf.lastName) || "",
      icon: note?.onBehalfOf?.name ? IconFileLookup.briefcase : undefined,
      iconCircleColor: "green",
      testId: "company",
      isRequired: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.OnBehalfOf === "Required" &&
          !note.project.id
        : false,
      isHidden: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.OnBehalfOf === "Hidden"
        : false,
      isDisabled: state.context.note.type.dataRuleSet
        ? state.context.note.type.dataRuleSet.OnBehalfOf === "Future release"
        : false,
    },
  };

  const typeCellsWhenEditing = [cells["cellRecordType"]];

  const optionalCellsWhenEditing = [
    cells["cellProject"],
    cells["cellClient"],
    cells["cellCandidate"],
    cells["cellCompany"],
    cells["cellOnBehalfOf"],
  ];

  const onSendNote = async () => {
    send(EVENT.SEND, {
      displayMessage: `Uploading: 0%`,
    });
    if (!note.file) {
      send(EVENT.READY, {
        displayMessage: NO_FILES_ARE_SELECTED,
        feedback: FEEDBACK_TYPE.ERROR,
      });
      return;
    }
    const progressCallback = (progress: number) => {
      send(EVENT.PROGRESS, {
        displayMessage: `Uploading: ${progress.toFixed(0).toString()}%`,
      });
    };
    const mentions = [
      ...Object.values(contactMentionList).map((contact) => {
        return {
          targetId: contact.id,
          displayName: `${contact.firstName} ${contact.lastName}`,
          targetType: TargetType.Contact,
          openCharacter: "@",
        };
      }),

      ...Object.values(projectMentionList).map((project) => {
        return {
          targetId: project.id,
          displayName: project.name,
          targetType: TargetType.Project,
          openCharacter: "@@",
        };
      }),
      ...Object.values(companyMentionList).map((company) => {
        return {
          targetId: company.id,
          displayName: company.name,
          targetType: TargetType.Company,
          openCharacter: "@@@",
        };
      }),
    ];
    const filterMentions = (mentions: MentionItem[]) => {
      return mentions.filter(
        (mention, index, self) =>
          index === self.findIndex((t) => t.targetId === mention.targetId)
      );
    };
    const filteredMentions = filterMentions(mentions);
    var auth_token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
    let noteToSend = {
      ...state.context.note,
    };
    noteToSend = getPrimaryAndSecondaryPerson(noteToSend);
    const result = await postNote(
      noteToSend,
      auth_token,
      progressCallback,
      filteredMentions
    ).catch((error) => {
      send(EVENT.READY, {
        displayMessage: `Server error: ${error}`,
        feedback: FEEDBACK_TYPE.ERROR,
      });
    });
    // If request failed, multiple types of error objects are returned.
    // If request was successful, result is a number, and not an object.
    if (typeof result === "object") {
      send(EVENT.READY, {
        displayMessage: "Error after server request",
        feedback: FEEDBACK_TYPE.ERROR,
      });
    } else {
      send(EVENT.READY, {
        displayMessage: "Upload completed",
        feedback: FEEDBACK_TYPE.SUCCESS,
      });
      setTimeout(() => window.location.reload(), 3000);
    }
  };

  const isEveryRequiredFieldFilled = () => {
    let shoudPass = false;

    if (state.context.note.type.dataRuleSet?.Client === "Required" && !note.client.id) {
      return shoudPass;
    } else if (
      state.context.note.type.dataRuleSet?.Candidate === "Required" &&
      !note.candidate.id
    ) {
      return shoudPass;
    } else if (
      state.context.note.type.dataRuleSet?.Project === "Required" &&
      !note.project.id
    ) {
      return shoudPass;
    } else if (
      state.context.note.type.dataRuleSet?.Company === "Required" &&
      !note.company.id
    ) {
      return shoudPass;
    } else if (
      state.context.note.type.dataRuleSet?.OnBehalfOf === "Required" &&
      !note.onBehalfOf?.id
    ) {
      return shoudPass;
    } else return true;
  };

  function isCommentFieldRequired() {
    return state.context.note.type.dataRuleSet
      ? state.context.note.type.dataRuleSet.Comments === "Required"
      : false;
  }

  const isCommentFieldFilled = () => {
    if (isCommentFieldRequired() && !note.comment) {
      return false;
    }
    return true;
  };

  const outlineStyle =
    isCommentFieldRequired() && !note.comment ? "solid red 2px" : "none";

  const isSendButtonEndabled = () => {
    return (
      !!note.file &&
      isEveryRequiredFieldFilled() &&
      !!note.type.label &&
      isCommentFieldFilled()
    );
  };

  const textAreaStyle = {
    height: "88px",
    width: "248px",
    padding: "12px",
    fontSize: "14px",
    color: "#8a8b8c",
    border: "1px solid #dbd5cd",
    borderRadius: "5px",
    alignSelf: "center",
    resize: "none",
    overflow: "auto",
    outline: outlineStyle,
    outlineOffset: "-2px",
  };

  const mentionContainerStyle = `
  position: relative;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 10px;
`;

  const finalCelss = optionalCellsWhenEditing.filter((cell: TUnionCell) => {
    return !cell.isHidden;
  });

  const handleDragAndDropUpload = (e: React.DragEvent<HTMLDivElement>) => {
    const selectedFile = e.dataTransfer.files[0];
    const fileSize = selectedFile.size / 1024 / 1024;

    if (fileSize > FILE_SIZE_LIMIT) {
      send(EVENT.CHANGE_NOTE, {
        dataType: NOTE_EVENT_TYPE.FILE_SIZE,
      });
      return;
    }

    send(EVENT.CHANGE_NOTE, {
      data: selectedFile,
      dataType: NOTE_EVENT_TYPE.FILE,
    });
  };

  return (
    <Container className={"assignment-container"} data-test-id="selector-modal">
      {!state.context.editorType && <ModalTitle />}
      <DragNDrop onDrop={handleDragAndDropUpload}>
        <CellGroupContainer>
          {!state.context.editorType && (
            <>
              <OptionalButtonGroup>
                <CellGroup type={"value"} cells={typeCellsWhenEditing} isTypeField />
              </OptionalButtonGroup>
              <FileUploader
                handleFile={(file: File) => {
                  send(EVENT.CHANGE_NOTE, {
                    data: file,
                    dataType: NOTE_EVENT_TYPE.FILE,
                  });
                }}
                handleFileSizeError={() => {
                  send(EVENT.CHANGE_NOTE, {
                    dataType: NOTE_EVENT_TYPE.FILE_SIZE,
                  });
                }}
                fileName={note.file?.name}
                allowedFileExtensions={state.context.allowedFileExtensions}
              />
            </>
          )}

          <OptionalContainer>
            {note?.type?.id && <SelectorComponent cells={finalCelss} />}
          </OptionalContainer>
        </CellGroupContainer>
        {isMentionFunctionalityEnabled ? (
          <Mention
            comment={note.comment}
            onCommentChange={(comment: string) => {
              send(EVENT.CHANGE_NOTE, {
                data: comment,
                dataType: NOTE_EVENT_TYPE.COMMENT,
              });
            }}
            textAreaStyle={textAreaStyle}
            mentionContainerStyle={mentionContainerStyle}
            mentionOptions={[
              {
                openingCharacter: "@",
                resultData: contactSearchTerm ? (contactData as Array<Contact>) : [],
                setSearchTerm: setContactSearchTerm,
                mentionList: contactMentionList,
                setMentionList: setContactMentionList,
                customComponent: ContactDropdownItem,
              },
              {
                openingCharacter: "@@",
                resultData: projectSearchTerm ? (projectData as Array<Project>) : [],
                setSearchTerm: setProjectSearchTerm,
                mentionList: projectMentionList,
                setMentionList: setProjectMentionList,
                customComponent: ProjectDropdownItem,
              },
              {
                openingCharacter: "@@@",
                resultData: companySearchTerm ? (companyData as Array<Company>) : [],
                setSearchTerm: setCompanySearchTerm,
                mentionList: companyMentionList,
                setMentionList: setCompanyMentionList,
                customComponent: CompanyDropdownItem,
              },
            ]}
          />
        ) : (
          <TextareaCapture
            value={note.comment}
            placeholder="Enter a comment"
            isRequired={!note.comment && isCommentFieldRequired()}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              send(EVENT.CHANGE_NOTE, {
                data: e.target.value,
                dataType: NOTE_EVENT_TYPE.COMMENT,
              });
            }}
            size={{ height: "88px", width: "248px" }}
          />
        )}

        {state.context.displayMessage && (
          <FeedbackDisplay
            type={state.context.feedback}
            message={state.context.displayMessage}
          />
        )}
        {state.context.editorType === NOTE_EVENT_TYPE.TYPE && (
          <SelectorEditRecordType
            onCancel={() => send(EVENT.END_SELECT, { data: null })}
            onSelected={(type) =>
              send(EVENT.END_SELECT, {
                data: type,
                dataType: NOTE_EVENT_TYPE.TYPE,
              })
            }
            conversationTypeCells={state.context.conversationTypeCells}
          />
        )}
      </DragNDrop>
      {state.context.editorType === NOTE_EVENT_TYPE.MENU && (
        <SelectorMenu
          handleSignOut={() => handleSignOut()}
          onCancel={() => {
            send(EVENT.CLOSE_MENU);
          }}
        />
      )}

      {state.context.feedback !== FEEDBACK_TYPE.SUCCESS && (
        <ButtonContainer>
          <Button
            text={state.value === STATE.UPLOADING ? UPLOADING : SEND_TO_ZENDAI}
            actionType={
              state.value === STATE.UPLOADING || !isSendButtonEndabled()
                ? BUTTON_ACTION.DISABLED
                : BUTTON_ACTION.DEFAULT
            }
            onClick={onSendNote}
            size={BUTTON_SIZE.MEDIUM}
          />
          <IconContainer
            onClick={() =>
              send(EVENT.OPEN_MENU, {
                dataType: NOTE_EVENT_TYPE.MENU,
              })
            }
          >
            <Icon type="hamburger" fill="#00000090" />
          </IconContainer>
        </ButtonContainer>
      )}
    </Container>
  );
};

const IconContainer = styled.div`
  cursor: pointer;
  margin-top: 3px;
  align-self: center;
  margin-right: 10px;
`;

const Container = styled.div`
  height: 565px;
  // TODO: Gradient to match current design - may need adjustments or move to a higher level component
  background: white;
  flex: 1 1 auto;
  /* overflow-x: hidden; */
  @media ${device.desktop} {
    width: 100%;
    /* overflow-y: auto; */
  }
`;

const OptionalContainer = styled.div`
  padding-left: 16px;
  padding-bottom: 14px;
  padding-top: 16px;
  border-top: 1px solid #dbd5cd;
  height: 263px;

  /* TODO: these 2 fixes may belong to DS */
  > div {
    height: initial;
  }

  .value-cell-container:last-child {
    border-bottom: none;
  }
`;

const OptionalButtonGroup = styled.div`
  border: 1px solid #dbd5cd;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  position: absolute;
  bottom: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
`;
