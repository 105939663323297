import { useState, SetStateAction, Dispatch } from "react";
import { Capacitor } from "@capacitor/core";

export enum LoadingState {
  Error = -1,
  Loading,
  Ready,
}

export type LoadingStateControls = [
  LoadingState,
  Dispatch<SetStateAction<LoadingState>>
];

export const unifiedSignIn = (hostName: string) => {
  if (Capacitor.isNativePlatform()) {
    /* signInMobile("azure-ad-mobile", hostName, Callback); */
  } else {
  }
};

// `useLoadingState` is a hook that provides the LoadingState enum as a hook.
// It can be destructed to gather the current loading of a module.
export const useLoadingState = (defaultLoadingState?: LoadingState) => {
  return useState<LoadingState>(defaultLoadingState || LoadingState.Loading);
};

// generateTestId takes a targetField that serves as a fallback, and a base that is prepended to allow for flexible test ids
export const generateTestId = (targetField: string, base?: string) => {
  return `${base ? `${base}-${targetField}` : targetField}`;
};

export function formatDate(inputDate: Date | string) {
  let dateObject: Date;

  if (typeof inputDate === "string") {
    dateObject = new Date(inputDate);
  } else {
    dateObject = inputDate;
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date, month, year;

  date = dateObject.getDate();
  month = dateObject.getMonth();
  year = dateObject.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month <= monthNames.length ? monthNames[month] : "Inv";

  return `${date} ${month} ${year}`;
}

export const getFileExtension = (filename: string): string => {
  return "." + filename?.split('.').pop()!.toLowerCase();
}
