import React from "react";
import styled from "@emotion/styled";
import LoadingSpinner from "../components/LoadingSpinner";

const LoadingView = () => {
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  );
};

export default LoadingView;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
