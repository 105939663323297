import { Capacitor } from "@capacitor/core";
import { unifiedSignIn } from "../utils";

/*
 * This file contains client API definitions
 * */

type APIClientConfig = {
  hostName: string;
  baseURL: string;
  headers?: Record<string, string>;
};

export const EZ_CAPTURE_CLIENT = "CaptureUI";

export type ApiError = {
  message: string;
};

export class APIClient {
  hostName: string;
  baseURL: string;
  headers: Record<string, string>;

  constructor(config: APIClientConfig) {
    this.hostName = config.hostName || "";
    this.baseURL = config.baseURL;
    this.headers = config.headers || {};
    this.headers["Content-Type"] = "application/json";
    this.headers["Access-Control-Allow-Origin"] = "*";
  }

  setHeader(key: string, value: string) {
    this.headers[key] = value;
  }

  async get<Response>(path: string, auth_token: string) {
    this.setHeader("Authorization", "Bearer " + auth_token);
    this.setHeader("EZ-Capture-Client", EZ_CAPTURE_CLIENT);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api${path}`,
      {
        method: "GET",
        headers: this.headers,
        credentials: Capacitor.isNativePlatform() ? "include" : undefined,
      }
    );
    if (response.status === 401) {
      unifiedSignIn(this.hostName);
    }

    const jsonResponse: Response = await response.json();
    return jsonResponse;
  }
}
