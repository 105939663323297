import React from "react";
import styled from "@emotion/styled";
import { SelectableListCell } from "./ListCell";
import Listbox from "./Listbox";
import { SELECT_FINAL_DOCUMENT_TYPE } from "./utils/i18n";
import { NoteType } from "./Selector";

type Props = {
  /**
   * Called when the user cancels out of the operation.
   * @returns void
   */
  onCancel: () => void;
  /**
   * Called when the user selects an item from the list.
   * @param id The id of conversation type.
   * @param label The label of conversation type.
   * @returns void
   */
  onSelected: (cellRecordType: NoteType) => void;
  conversationTypeCells: NoteType[];
};

/**
 * Displays the assignments configured for the note. If editing, the user will be allowed to
 * change the assignments.
 * @param onCancel - Called when the user cancels out of the operation.
 * @param onSelected - Called when the user selects an item from the list.
 * @returns ReactNode
 */
export const SelectorEditRecordType: React.FC<Props> = ({
  onCancel,
  onSelected,
  conversationTypeCells,
}) => (
  <ConfigurationContainer
    className={"assignment-edit-container conversation-type"}
  >
    <Listbox
      listData={conversationTypeCells}
      renderItem={(result: NoteType) => (
        <SelectableListCell
          key={result.id}
          headline={result.label ?? ""}
          icon={result.icon}
          onClick={() => onSelected && onSelected(result)}
          noteType={true}
          fieldType="record"
        />
      )}
      onCancel={onCancel}
      onSelection={(item) => {
        onSelected && onSelected(item);
      }}
      titleActivity={SELECT_FINAL_DOCUMENT_TYPE}
      isLoading={!conversationTypeCells.length}
    />
  </ConfigurationContainer>
);

const ConfigurationContainer = styled.div`
  background: white;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
