// Reference Primitives
export const black = "#000000";

export const grey = {
  800: "#161717",
  700: "#414244",
  600: "#57595B",
  500: "#737578",
  400: "#8F9194",
  300: "#ACADAF",
  200: "#C6C7C8",
  100: "#E2E3E4",
};

export const white = "#FFFFFF";

export const warmgrey = {
  900: "#1E1A15",
  800: "#3E372D",
  600: "#7C6D5A",
  700: "#5C5142",
  500: "#988771",
  400: "#AEA08F",
  300: "#C5BCAF",
  200: "#DBD5CD",
  100: "#EEEBE7",
};

export const cranberry = {
  1000: "#2D060D", //TODO
  900: "#2D060D",
  800: "#590D1B",
  700: "#8A142A",
  600: "#B61A37",
  500: "#E02447",
  400: "#E6516D",
  300: "#EC798E",
  200: "#F2A6B4",
  100: "#F9D2D9",
};

export const blue = {
  1000: "#162737",
  900: "#223B53",
  800: "#2D4F6F",
  700: "#375F86",
  600: "#5A7B9B",
  500: "#7792AC",
  400: "#94A9BE",
  300: "#B1C1D0",
  200: "#CFD8E2",
  100: "#EDF1F4",
};

export const orange = {
  1000: "#382101",
  900: "#543102",
  800: "#724202",
  700: "#915303",
  600: "#B06603",
  500: "#E18105",
  400: "#E5952C",
  300: "#EDB46A",
  200: "#F4D3A7",
  100: "#FBF0E0",
};

export const timberwolf = {
  1000: "#685F54",
  900: "#766E63",
  800: "#857D72",
  700: "#A19A91",
  600: "#BEB8AF",
  500: "#DBD5CD",
  400: "#E2DDD7",
  300: "#EEEBEA",
  200: "#F2F2F2",
  100: "#F6F6F6",
};

// Additional
const darkGrey = "#343535";

// System Colors
// Background

export const background = {
  bg: white,
  bgBrand: cranberry[700],
  bgBrandContainerLite: cranberry[100],
  bgSecondary: blue[700],
  bgSecondaryContainerLite: blue[200],
  bgDisabled: grey[200],
  bgDisabledContainer: grey[100],
  bgListItem: "#EFEFEF",
  bgListSelected: blue[100],
  bgSecondaryHover: blue[800],
};

// Text
export const textColors = {
  textBrand: cranberry[700],
  textSecondary: blue[700],
  textOnBgStrong: grey[500],
  textOnBgMedium: grey[600],
  textOnBrand: white,
  textOnBrandContainer: cranberry[700],
  textOnSecondaryContainer: blue[900],
  textOnDisabled: grey[300],
  textOnBgDark: darkGrey,
};

// Icon
export const iconColors = {
  iconSecondary: blue[700],
  iconOnBgStrong: grey[800],
  iconOnBgLite: grey[300],
  iconOnBrandContainer: cranberry[700],
  iconOnBrand: white,
  iconOnSecondaryContainer: blue[900],
  iconOnDisabled: grey[300],
};

// Border
export const borderColors = {
  borderBrand: cranberry[700],
  borderBrandContainer: cranberry[100],
  borderSecondary: blue[700],
  borderSecondaryContainer: blue[100],
  borderOnBg: grey[100],
  borderDisabled: grey[200],
  // Border Interaction
  borderBrandHover: cranberry[400],
  borderSecondaryHover: blue[400],
  borderOnBrandHover: grey[200],
};

// THEMES SHOULD BECOME EMOTION THEMES
// Alpha Theme
// $alpha-theme: $blue-800;
// $alpha-theme-container-strong: $blue-600;
// $alpha-theme-container: $blue-500;
// $alpha-theme-container-medium: $blue-400;
// $alpha-theme-container-lite: $blue-200;
// $alpha-ontheme-strong: $blue-600;
// $alpha-ontheme-lite: $blue-100;
// $alpha-theme-strong-hover: $blue-600;
// $alpha-theme-medium-hover: $blue-400;
//
// // Beta Theme
// $beta-theme: $orange-500;
// $beta-theme-container-strong: $orange-600;
// $beta-theme-container: $orange-400;
// $beta-theme-container-medium: $orange-300;
// $beta-theme-container-lite: $orange-200;
// $beta-ontheme-strong: $orange-600;
// $beta-ontheme-lite: $orange-100;
// $beta-theme-strong-hover: $orange-600;
// $beta-theme-medium-hover: $orange-400;

// Avatars
export const avatarColors = {
  bgAvatarGreen: "#009A06",
  bgAvatarPurple: "#4D3CB0",
  bgAvatarAqua: "#008090",
  bgAvatarTangerine: "#E56F06",
  bgAvatarLilac: "#A800DF",
  bgAvatarSea: "#186BBA",
  bgAvatarRed: "#CD3100",
};

// System dialogs
export const dialog = {
  success: "#198038",
  warning: "#ECAA00",
  info: "#4589FF",
  linkvisited: "#4242A4",
  error: "#DA1E28",
};

export const gradient = {
  brandBerry: "linear-gradient(90deg, #DC93A0 0%, #C65065 100%)",
  blueDarkSky: "linear-gradient(90deg, #94A9BE 0%, #375F86 77.08%)",
  headerSnow:
    "radial-gradient(75% 4540.03% at 0% 0%, rgba(177, 193, 208, 0.3) 0%, rgba(177, 193, 208, 0) 100%), #F2F2F2",
  bgIron: "linear-gradient(90deg, #F8F8F8 0%, #D7D7D7 100%)",
  bgSilver: "linear-gradient(0deg, #F6F6F6 0%, #F6F6F6 0.01%, #EEEBEA 100%)",
  // scales
  sunsetLeft: "linear-gradient(90deg, #E5952C 0%, #EDB46A 100%)",
  sunsetRight: "linear-gradient(90deg, #EDB46A 0%, #E5952C 100%)",
  mercuryLeft:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.8) 19.79%, rgba(255, 255, 255, 0.4) 80.31%), #D7D7D7",
  mercuryRight:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 19.79%, rgba(255, 255, 255, 0.8) 80.31%), #D7D7D7",
};
