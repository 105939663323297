import React from "react";
import { BUTTON_SIZE, Button } from "@ez/design-system";
import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";

const StyledBox = styled(Box)({
  marginTop: "90px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
});

export const NotFound = () => {
  let navigate = useNavigate();

  return (
    <StyledBox>
      <p>Page not found...</p>
      <Button size={BUTTON_SIZE.LARGE} text="Back to the home page" onClick={() => navigate("/")} />
    </StyledBox>
  );
};
