import { Note, MentionItem } from "../../../components/Selector";
import { EZ_CAPTURE_CLIENT } from "../../client";

export const postNote = (
  note: Note,
  auth_token: string,
  progressCallback: (percent: number) => void,
  mentions: MentionItem[] = []
): Promise<any> => {
  const formData = new FormData();

  // TODO: Update the formData with the correct fields
  note.file && formData.append("uploadedFile", note.file);
  note.company.id && formData.append("companyId", note.company.id.toString());
  note.project.id && formData.append("projectId", note.project.id.toString());
  note.primaryPerson.id &&
    formData.append("primaryPersonId", note.primaryPerson.id.toString());
  note.secondaryPerson.id &&
    formData.append("secondaryPersonId", note.secondaryPerson.id.toString());
  note.onBehalfOf.id && formData.append("onBehalfOfId", note.onBehalfOf.id.toString());
  note.comment && formData.append("comments", note.comment);
  note.type.id && formData.append("recordTypeId", note.type.id);
  mentions.length > 0 && formData.append("mentions", JSON.stringify(mentions));

  const xhr = new XMLHttpRequest();

  xhr.upload.onprogress = function (event) {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      progressCallback(percentComplete);
    }
  };

  xhr.open("POST", `${process.env.REACT_APP_API_BASE_URL}/api/filerecords/upload`, true);
  xhr.setRequestHeader("Authorization", "Bearer " + auth_token);
  xhr.setRequestHeader("EZ-Capture-Client", EZ_CAPTURE_CLIENT);
  xhr.send(formData);

  const postSent = (resolve: Function, reject: Function) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 400) {
          reject(xhr.responseText);
        } else {
          resolve();
        }
      }
    };
  };

  return new Promise(postSent);
};
