import React from "react";
import ContentEditable from "react-contenteditable";
import "../../index.css";

class MentionDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      html: this.props.html,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.html !== this.props.html ||
      nextState.html !== this.state.html ||
      nextProps.style !== this.props.style ||
      nextState.style !== this.state.style
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.html !== prevState.html) {
      return { html: nextProps.html };
    }
    if (nextProps.style !== prevState.style) {
      return { style: nextProps.style };
    }
    return null;
  }

  handleChange = (evt) => {
    const replaceDivWithBr = (text) => text.replace(/<div><br><\/div>/g, "<br>");
    const replaceDivClosing = (text) => text.replace(/<\/div>/g, "<br>");
    const replaceDivOpening = (text) => text.replace(/<div>/g, "");

    const replaceHtml = replaceDivWithBr(
      replaceDivOpening(replaceDivClosing(evt.target.value))
    );
    this.setState({ html: evt.target.value });
    this.props.onChange(replaceHtml);
  };

  isEnterOrShiftEnter = (evt) => {
    const isEnter = evt.keyCode === 13 && !evt.shiftKey;
    const isShiftEnter = evt.keyCode === 13 && evt.shiftKey;
    if (isEnter) {
      evt.preventDefault();
    }

    if (isEnter || isShiftEnter) {
      //evt.preventDefault();
    }
  };

  render = () => {
    return (
      <>
        <ContentEditable
          html={this.state.html}
          onChange={this.handleChange}
          innerRef={this.props.commentFieldRef}
          style={this.props.style}
          className="mention-focus"
          onKeyDown={this.isEnterOrShiftEnter}
        />
      </>
    );
  };
}

export default MentionDisplay;
