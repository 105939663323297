export const spacing = {
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "40px",
  11: "44px",
  12: "48px",
};

export const newSpacing = {
  1: "8px",
  2: "12px",
  3: "16px",
  4: "24px",
  5: "32px",
  6: "48px",
  7: "64px",
  8: "72px",
};
