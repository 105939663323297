import React, { ReactNode } from "react";
import { LoadingState } from "../../utils";

type Props = {
  loading?: LoadingState;
  onError?: ReactNode;
  onLoading?: ReactNode;
  children?: ReactNode;
};

const LoadingWrapper: React.FC<Props> = ({
  children,
  loading,
  onError,
  onLoading,
}) => {
  switch (loading) {
    case LoadingState.Ready:
      return <>{children}</>;
    case LoadingState.Error:
      return onError ? <>{onError}</> : <>{children}</>;
    case LoadingState.Loading:
      return onLoading ? <>{onLoading}</> : <>{children}</>;
    default:
      return null;
  }
};

export default LoadingWrapper;
