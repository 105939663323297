import styled from "@emotion/styled";
import * as React from "react";
import { useEffect } from "react";
import {
  cleanUpMentionListsFromDeletedMentions,
  createMentionSpan,
  debounce,
  getCaretCharacterOffsetWithin,
  getCaretLine,
  isContact,
  replaceBrTagWithEmptyStringForNames,
  replaceMentionIdsWithNames,
  revertSpansToPlaceholders,
  setResults,
  updateCaretPosition,
} from "./mentionUtils"; // Import utility functions for mentions
import MentionDisplay from "./MentionDisplay";
import { ListDropdown } from "@ez/design-system";
import { MentionOptions, PossibleMentionType } from "../Selector";

// Define the props interface for Mention component
interface IMentionProps {
  comment: string; // Comment string
  onCommentChange: Function; // Function to handle comment change
  textAreaStyle: object; // Style object for the text area
  mentionContainerStyle: string; // Style string for the mention container
  mentionOptions: MentionOptions[]; // Array of mention options
}

// Define the Mention functional component
export const Mention: React.FunctionComponent<IMentionProps> = ({
  comment: commentInIdFormat,
  onCommentChange: setCommentInIdFormat,
  textAreaStyle,
  mentionContainerStyle,
  mentionOptions,
}) => {
  const commentFieldRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [caretPosition, setCaretPosition] = React.useState(0);
  const [caretLine, setCaretLine] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isMentionModalOpen, setIsMentionModalOpen] = React.useState<boolean>(false);
  const [searchTermSetter, setSearchTermSetter] = React.useState<Function | null>(null);
  const [mentionListSetter, setMentionListSetter] = React.useState<Function | null>(null);
  const [openingCharacter, setOpeningCharacter] = React.useState({
    character: "",
    position: 0,
  });

  const openingCharacters = mentionOptions.map((option) => option.openingCharacter);

  useEffect(() => {
    const updateCaretInfo = () => {
      if (commentFieldRef.current) {
        const caretPos = getCaretCharacterOffsetWithin(commentFieldRef.current);
        const caretLine = getCaretLine(commentFieldRef.current);
        setCaretPosition(caretPos);
        setCaretLine(caretLine);
      }
    };

    const textarea = commentFieldRef.current;
    if (textarea) {
      textarea.addEventListener("keyup", updateCaretInfo);
      textarea.addEventListener("mouseup", updateCaretInfo);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("keyup", updateCaretInfo);
        textarea.removeEventListener("mouseup", updateCaretInfo);
      }
    };
  }, []);

  useEffect(() => {
    if (caretPosition < openingCharacter.position) {
      resetSearch();
    }
    debouncedPossibleOpeningCharacterSearch(caretPosition);
  }, [caretPosition]);

  useEffect(() => {
    searchTermSetter && searchTermSetter(searchTerm.slice(0, searchTerm.length));
  }, [searchTerm]);

  const checkForPossibleOpeningCharacters = (caretPosition: number) => {
    if (caretPosition > 0) {
      const textToUse = replaceBrTagWithEmptyStringForNames(
        replaceMentionIdsWithNames(commentInIdFormat, mentionOptions)
      );

      const openingCharacters = mentionOptions
        .map((option) => option.openingCharacter)
        .sort((a, b) => a.length - b.length);

      let openingCharacter = "";

      openingCharacters.forEach((character) => {
        if (
          textToUse.slice(caretPosition - character.length, caretPosition) === character
        ) {
          setOpeningCharacter({
            character: character,
            position: caretPosition,
          });
          openingCharacter = character;
        }
      });

      return openingCharacter;
    }
  };

  const debouncedPossibleOpeningCharacterSearch = debounce((caretPosition: number) => {
    const setCharacterOptions = (openingCharacter: string) => {
      function getSetter() {
        return mentionOptions.find(
          (option) => option.openingCharacter === openingCharacter
        )?.setSearchTerm;
      }
      function getCurrentMentionListSetter() {
        return (mentionOptions as Array<any>).find(
          (option) => option.openingCharacter === openingCharacter
        ).setMentionList;
      }

      if (openingCharacter) {
        setSearchTermSetter(getSetter);
        setMentionListSetter(getCurrentMentionListSetter);
      }
    };

    const openingCharacter = checkForPossibleOpeningCharacters(caretPosition);
    if (openingCharacter) {
      setCharacterOptions(openingCharacter);
      setIsMentionModalOpen(true);
    }
  }, 100);

  const handleSearch = (commmentWithNames: string, caretPosition: number) => {
    let lastAtIndex = -1;
    let matchedOpeningCharacter = "";

    const textToUse = replaceBrTagWithEmptyStringForNames(commmentWithNames);

    for (const character of openingCharacters) {
      const index = textToUse.lastIndexOf(character, caretPosition);
      if (index !== -1 && index > lastAtIndex) {
        lastAtIndex = index;
        matchedOpeningCharacter = character;
      }
    }
    if (lastAtIndex !== -1 && caretPosition > lastAtIndex) {
      const term = textToUse.slice(
        lastAtIndex + matchedOpeningCharacter.length,
        caretPosition + 1
      );
      // reset search term if there are two spaces in the search term or a single space immediately after the opening character
      if (term.includes("  ") || (term.length === 1 && term === " ")) {
        resetSearch();
      } else {
        setSearchTerm(term);
      }
    } else {
      setSearchTerm("");
    }
  };

  const resetSearch = () => {
    setSearchTerm("");
    setIsMentionModalOpen(false);
    setOpeningCharacter({
      character: "",
      position: 0,
    });
  };

  const handleChange = (value: any) => {
    const commentWIthoutAsciiSpaces = value.replace(/&nbsp;/g, " ");
    const commentWithIds = revertSpansToPlaceholders(
      commentWIthoutAsciiSpaces,
      mentionOptions
    );

    cleanUpMentionListsFromDeletedMentions(commentWithIds, mentionOptions);
    setCommentInIdFormat(commentWithIds);

    if (isMentionModalOpen) {
      handleSearch(
        replaceMentionIdsWithNames(commentWithIds, mentionOptions),
        caretPosition
      );
    }
  };

  const replaceMentions = (text: string) => {
    mentionOptions.forEach((option) => {
      const { openingCharacter, mentionList } = option;
      const regex = new RegExp(
        `${openingCharacter}([^${openingCharacter}]+)\\/${openingCharacter}`,
        "g"
      );

      text = text.replace(regex, (match, id) => {
        const mention = mentionList[id];
        // If mention found in the text, replace it with a span element
        if (mention) {
          return createMentionSpan(mention, openingCharacter);
        }
        return match;
      });
    });
    return text;
  };

  const findOpeningCharacterPositionAndLength = (text: string, caretPosition: number) => {
    const specialCharacters = mentionOptions.map((option) => option.openingCharacter);

    for (let character of specialCharacters) {
      const index = text.lastIndexOf(character, caretPosition);
      if (index !== -1 && index + character.length <= caretPosition + 1) {
        return {
          specialCharacterPosition: index,
          specialCharacterLength: character.length,
        };
      }
    }

    return {
      specialCharacterPosition: 0,
      specialCharacterLength: 0,
    };
  };

  const handleMentionItemSelect = (
    item: PossibleMentionType,
    searchTerm: string,
    commentInIdFormat: string
  ) => {
    let name = "";
    if (isContact(item)) {
      name = `${item.firstName} ${item.lastName}`;
    } else {
      name = item.name ?? "";
    }

    const nameToBeReplaced = `${openingCharacter.character}${searchTerm}`;
    const idToReplace = `${openingCharacter.character}${item.id}/${openingCharacter.character}`;
    const commentInNameFormat = replaceMentionIdsWithNames(
      replaceBrTagWithEmptyStringForNames(commentInIdFormat),
      mentionOptions
    );

    const { specialCharacterPosition, specialCharacterLength } =
      findOpeningCharacterPositionAndLength(commentInNameFormat, caretPosition);

    const extraPosition = caretLine > 1 ? caretLine - 1 : 0;
    const characterLenght = specialCharacterLength > 1 ? 1 : specialCharacterLength;
    const beforeText = commentInNameFormat.slice(
      0,
      specialCharacterPosition + characterLenght + extraPosition
    );

    setCommentInIdFormat(commentInIdFormat.replace(nameToBeReplaced, idToReplace));
    mentionListSetter &&
      mentionListSetter((prev: any) => ({
        ...prev,
        [`${item.id}`]: item,
      }));
    resetSearch();
    commentFieldRef.current!.focus();

    setTimeout(() => {
      const newCaretPos =
        beforeText.length + name.length - openingCharacter.character.length + 1;
      updateCaretPosition(commentFieldRef.current!, newCaretPos);
      commentFieldRef.current!.focus();
    }, 100);
  };

  const PassedInComponentWithOnClick: React.FC<{ item: any }> = ({ item }) => {
    const PassedInComponent: React.ComponentType<any> | React.FC<any> =
      mentionOptions.find(
        (option) => option.openingCharacter === openingCharacter.character
      )?.customComponent!;

    return (
      <PassedInComponent
        item={item}
        onClick={() => handleMentionItemSelect(item, searchTerm, commentInIdFormat)}
        key={""}
      />
    );
  };

  return (
    <MentionContainer mentionContainerStyle={mentionContainerStyle} ref={dropdownRef}>
      {isMentionModalOpen && (
        <ListDropdown
          topPosition="88px"
          width="244px"
          leftPosition="17px"
          numberOfItemShown={4}
          results={setResults(mentionOptions, openingCharacter.character) as Array<any>}
          passedInComponent={PassedInComponentWithOnClick}
        />
      )}
      <MentionDisplay
        style={textAreaStyle}
        html={replaceMentions(commentInIdFormat)}
        onChange={handleChange}
        commentFieldRef={commentFieldRef}
      />
    </MentionContainer>
  );
};

const MentionContainer = styled("div")<{
  mentionContainerStyle: string;
}>`
  ${(props) => props.mentionContainerStyle}
`;
