// example breakpoint usage for emotion:
/*
    ...
    @media ${device.tablet} {
        // go for it
    }
    ...
 */

enum size {
  TabletSm = 580, // 580
  Tablet = 768, // 768
  Desktop = 1024, // 1024
  DesktopLarge = 1280, // 1280
  DesktopXLarge = 1440, // 1440
  Inf = 1920, // 1920
}

export const device = {
  mobile: `(max-width: ${size.TabletSm}px)`, // below 580
  tabletSm: `(min-width: ${size.TabletSm}px)`, // 580
  tablet: `(min-width: ${size.Tablet}px)`, // 768
  desktop: `(min-width: ${size.Desktop}px)`, // 1024
  desktopLarge: `(min-width: ${size.DesktopLarge}px)`, // 1280
  desktopXLarge: `(min-width: ${size.DesktopXLarge}px)`, // 1440
  inf: `(min-width: ${size.Inf}px)`, // 1920
};

// old sizes from sass
export const desktopSize = "1440px";
export const tabletSize = "1024px";
export const mobileLandscapeSize = "926px";
export const mobileSize = "428px";
