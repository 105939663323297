import styled from "@emotion/styled";
import React, { useRef } from "react";
import { SELECT_FINAL_DOCUMENT } from "./utils/i18n";
import { FILE_SIZE_LIMIT } from "../services/constants";
import MissingIndicator from "./MissingIndicator";
import { Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";

type Props = {
  fileName?: string;
  handleFile: (file: File) => void;
  handleFileSizeError: () => void;
  allowedFileExtensions: string[];
};

export const FileUploader: React.FC<Props> = ({
  fileName,
  handleFile,
  handleFileSizeError,
  allowedFileExtensions,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  function onFileLoaded(e: any) {
    const filesToUpload = hiddenFileInput.current
      ? hiddenFileInput.current.files
      : null;

    if (!filesToUpload || filesToUpload.length === 0) {
      return;
    }

    const selectedFile = filesToUpload[0];
    const fileSize = selectedFile.size / 1024 / 1024; // in MB

    if (fileSize > FILE_SIZE_LIMIT) {
      handleFileSizeError();
      return;
    }

    const fileName = hiddenFileInput?.current?.value;
    fileName && handleFile(selectedFile);
  }

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: any) => {
    var files = event.target.files;
    if (files.length < 1) {
      return;
    }
    var file = files[0];
    var reader = new FileReader();
    reader.onload = onFileLoaded;
    reader.readAsDataURL(file);
  };

  const StateLightColorImage = styled("img")`
    // TODO: Zendai/state/light/state4
    // filter based on rgba(0, 0, 0, 0.32)
    margin-left: -30px;
  `;

  return (
    <StyledContainer>
      {!fileName && <MissingIndicator />}
      <StyledButton className="button-upload" onClick={handleClick}>
        {!fileName && SELECT_FINAL_DOCUMENT}
        <Tooltip
          title={fileName}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 1000 }}
        >
          <FileName>{fileName}</FileName>
        </Tooltip>
      </StyledButton>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        accept={allowedFileExtensions.join(",")}
      />

      <StateLightColorImage
        src={"/icons/chevron-right.svg"}
        alt={"Icon"}
        width={12}
        height={18}
        draggable={false}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled("div")`
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    margin-bottom: -45px;
    width: 100%;
  }
`;

const StyledButton = styled("button")`
  cursor: pointer;
  font: inherit;
  min-height: 45px;
  min-width: 100%;
  text-align: left;
  background-color: white;
  border: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-left: 16px;
  &:hover {
    background: #efefef;
  }
`;

const FileName = styled("span")`
  float: left;
  white-space: nowrap;
  max-width: 215px;
  margin-right: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  direction: rtl;
  color: rgba(0, 0, 0, 0.64);
`;
